export const KILOBYTE = 1000
export const MEGABYTE = 1000 * KILOBYTE

const converter = (size: number, abbreviation: number) => (size / abbreviation).toFixed(2)

export const convertFileSize = {
  toMegabytes: (size: number) => converter(size, MEGABYTE),
  toKilobytes: (size: number) => converter(size, KILOBYTE),
}

export const INITIAL_LOCATION_PATH = 'initialLocationPath'

/**
 * Maximum number of budgets allowed for each tenant
 */
export const MAXIMUM_BUDGETS_ALLOWED = 30

/**
 * Maximum number of target impressions allowed for each tenant
 */
export const MAXIMUM_TARGET_IMPRESSIONS_ALLOWED = 50

/**
 * Maximum value of target impressions
 */
export const MAX_TARGET_IMPRESSIONS_VALUE = 999999999999

export const PROTOCOL_REGEXP = /https?:\/\//
export const DOMAIN_REGEXP =
  /(\*|([a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}))/gi
