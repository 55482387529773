import { Typography } from '@material-ui/core'
import { CampaignStatus } from 'core/type'
import translate from 'i18next'

import type { CampaignFailDetails } from '../../legacy/common/CampaignFailDetails'

export const StatusDescription = (status: CampaignStatus, errorDetails?: CampaignFailDetails) => {
  const failStatusDescription = translate.t(`campaigns.failedStatusDescription.${errorDetails?.failureCode}`)
  const nonFailStatusDescription = translate.t(`campaigns.statusDescription.${status}`)

  const message = status === CampaignStatus.failed ? failStatusDescription : nonFailStatusDescription

  return (
    <Typography style={status === CampaignStatus.failed ? { color: 'rgba(250, 8, 8, 1)' } : {}} variant='caption'>
      {message}
    </Typography>
  )
}
