import Axios from '../../core/axios'
import type { ICampaignComments, NoteComment } from './types'

export class NotesAPI {
  static async get(campaignId: number | string) {
    try {
      const { data } = await Axios.get(`/campaigns/${campaignId}/comments?groups[]=user`, {
        headers: { Accept: 'application/json' },
      })

      return arraySerializer(data)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while getting Note')

      return Promise.reject(error)
    }
  }

  static async patch(commentId: number | string, note: NoteComment) {
    try {
      const { data } = await Axios.patch(`/campaign-comments/${commentId}?groups[]=user`, note)

      return noteSerializer(data)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while patching tenant')

      return Promise.reject(error)
    }
  }

  static async post(campaignId: number | string, note: NoteComment) {
    try {
      const { data } = await Axios.post(`/campaigns/${campaignId}/comments?groups[]=user`, note)

      return noteSerializer(data)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while posting tenant')

      return Promise.reject(error)
    }
  }

  static async delete(commentId: number | string) {
    try {
      await Axios.delete(`/campaign-comments/${commentId}`)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while delete tenant')

      return Promise.reject(error)
    }
  }
}

function arraySerializer(note: Array<ICampaignComments>) {
  return note.map((noteItem: ICampaignComments) => {
    const { id, comment, user } = noteItem

    return { id, comment, user }
  })
}

function noteSerializer(note: ICampaignComments) {
  const { id, comment, user } = note

  return [{ id, comment, user }]
}
