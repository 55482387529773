import { Box, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, createStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@mui/material'
import Axios from 'core/axios'
import type { AMPHTML, AddedAMPHTML, EnhancedCampaign } from 'core/type'
import translate from 'i18next'
import type { FC } from 'react'
import React, { useEffect, useMemo, useState } from 'react'
// @ts-ignore
import { useTranslation } from 'react-i18next'

import AMPHTMLZIPParser from '../../../../../../../libs/amphtml/parsers/AMPHTMLZIPParser'
import { getFileNameFromURL } from '../../../../../common/utils/AMPHTMLToImage'
import HandleAMPHTMLDeviceType from '../../common/HandleAMPHTMLDeviceType'

export type Device = 'mobile' | 'desktop'
export type PreviewVariant = 'wizard' | 'tab'

interface Props {
  campaign: EnhancedCampaign
}

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      fontWeight: 600,
      fontSize: 20,
      color: 'rgba(0, 0, 0, 0.88)',
      marginBottom: 10,
    },
  })
)

const usePreviewStyles = makeStyles({
  title: {
    marginTop: 32,
    fontWeight: 600,
    fontSize: 20,
    color: 'rgba(0, 0, 0, 0.88)',
  },
  previewContainer: {
    marginTop: 16,
  },
  file: {
    fontSize: 16,
    fontWeight: 400,
  },
})

export interface AMPHTMLParseResult {
  width: number
  height: number
  html: string
}

interface ISelectableFiles {
  files: Array<AMPHTML | AddedAMPHTML>
  activeFileIndex: number
  setActiveFileIndex: (index: number) => void
}

const SelectableFiles: FC<ISelectableFiles> = ({ files, activeFileIndex, setActiveFileIndex }) => {
  const classes = usePreviewStyles()

  return useMemo(() => {
    if (files.length === 1) {
      return (
        <Typography className={classes.file}>
          {files[0].url ? getFileNameFromURL(files[0].url) : files[0].name}
        </Typography>
      )
    } else {
      return (
        <FormControl component='fieldset'>
          <RadioGroup value={files[activeFileIndex]?.name || files[activeFileIndex]?.url}>
            {files.map((file, index) => {
              return (
                <FormControlLabel
                  key={file.url}
                  value={file.name || file.url}
                  control={<Radio color='primary' />}
                  label={file.url ? getFileNameFromURL(file.url) : file.name}
                  onClick={() => setActiveFileIndex(index)}
                />
              )
            })}
          </RadioGroup>
        </FormControl>
      )
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [files.length, activeFileIndex])
}

const BannerContent: FC<Props> = ({ campaign }) => {
  const { files, devices } = campaign
  const { label } = useStyles()
  const { t } = useTranslation()
  const classes = usePreviewStyles()
  const [deviceType, setDeviceType] = useState<Device>(devices.desktop ? 'desktop' : 'mobile')
  const [activeFileIndex, setActiveFileIndex] = useState<number>(0)
  const [parseResult, setParseResult] = useState<AMPHTMLParseResult | undefined>()

  useEffect(() => {
    // prettier-ignore
    (async () => {
      if (files[activeFileIndex]) {
        if ('file' in files[activeFileIndex]) {
          const parsedResult = await new AMPHTMLZIPParser((files[activeFileIndex] as AddedAMPHTML).file).transform()
          setParseResult(parsedResult)

          return
        }

        if ('url' in files[activeFileIndex]) {
          const { data } = await Axios.get((files[activeFileIndex] as AMPHTML).url, {
            responseType: 'arraybuffer',
          })
          const downloadedZip = new Blob([data])

          const parsedResult = await new AMPHTMLZIPParser(downloadedZip).transform()
          setParseResult(parsedResult)
        }
      }
    })()
  }, [files, activeFileIndex])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeviceType((event.target as HTMLInputElement).value as Device)
  }

  return (
    <Box mt={2}>
      <Grid container spacing={2} justifyContent={'flex-start'} direction={'row'}>
        <Grid item xs={6}>
          <FormControl component='fieldset'>
            <FormLabel component='legend' className={label}>
              {t('campaigns.preview.selectDevice')}
            </FormLabel>
            <RadioGroup row aria-label='device' name='device' value={deviceType} onChange={handleChange}>
              <FormControlLabel
                value='desktop'
                disabled={!devices.desktop}
                control={<Radio color='primary' />}
                label={t('campaigns.preview.desktop')}
              />
              <FormControlLabel
                value='mobile'
                disabled={!devices.mobile}
                control={<Radio color='primary' />}
                label={t('campaigns.preview.mobile')}
              />
            </RadioGroup>
          </FormControl>
          <FormLabel component='legend' className={classes.title}>
            {t('campaigns.preview.selectAmphtml')}
          </FormLabel>
          <Box className={classes.previewContainer}>
            <SelectableFiles files={files} activeFileIndex={activeFileIndex} setActiveFileIndex={setActiveFileIndex} />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <HandleAMPHTMLDeviceType deviceType={deviceType} parseResult={parseResult} />
        </Grid>
      </Grid>
    </Box>
  )
}

export const CampaignAMPHTMLBannerTab: FC<{ campaign: EnhancedCampaign }> = ({ campaign }) => (
  <BannerContent campaign={campaign} />
)

export const WizardAMPHTMLBannerStep = (campaign: EnhancedCampaign) => {
  return {
    isSavedOnPrev: true,
    Content: <BannerContent campaign={campaign} />,
    isValid: true,
    isSubmitting: false,
    isValidating: false,
    errors: {},
    label: translate.t('campaigns.preview.preview'),
    submit: () => Promise.resolve(true),
    dirty: false,
    isOptional: true,
    resetForm: () => Promise.resolve(),
    isShown: true,
  }
}
