import { Chip as MuiChip } from '@material-ui/core'
import type { FC } from 'react'

import { useChipStyles } from './styles'

export interface IChipProps {
  label: string
}

export const Chip: FC<IChipProps> = ({ label }) => {
  const styles = useChipStyles()

  return <MuiChip label={label} className={`${styles.MuiChip}`} />
}

export default Chip
