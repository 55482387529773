import type { FormikInputProps } from 'common/components/Inputs/FormikMaterialInput'
import type { EnhancedCampaign } from 'core/type'
import translate from 'i18next'
import translator from 'i18next'
import * as Yup from 'yup'

import type { AsyncStepProps } from './FormBuilder'

/* prioritizes props in a1 according to a2, 0(n + m) */
const prioritizeProps = (a1: Array<string>, a2: Array<string>) => {
  const register: Record<string, string> = {}

  for (const prop of a1) {
    register[prop] = prop
  }

  const result = []

  for (const prop of a2) {
    if (prop in register) {
      result.push(prop)

      delete register[prop]
    }
  }

  return result.concat(...Object.values(register))
}

export interface ApiSchema {
  '@schema': string
  $schema: string
  $ref: string
  definitions: {
    [schemaName: string]: {
      type: string // 'object';
      description: string // 'Ad';
      required?: Array<string>
      properties: {
        [prop: string]: {
          description: string
          type: Array<string> // ['string', 'null'];
          default?: string
          example?: string
          minLength?: number
          maxLength?: number
          format?: string // 'iri-reference';
        }
      }
    }
  }
}

export class SchemaParser {
  static preprocessSchema(
    apiSchema: ApiSchema,
    fieldsToOmit?: AsyncStepProps['fieldsToOmit'],
    declareRequired?: AsyncStepProps['declareRequired']
  ): ApiSchema {
    const { definitions } = apiSchema
    const [schemaName] = Object.keys(definitions)
    const { properties, required } = definitions[schemaName]

    if (fieldsToOmit && required) {
      for (const fieldToOmit of fieldsToOmit) {
        if (fieldToOmit in properties) {
          delete properties[fieldToOmit]
        }

        const indexToDelete = required.indexOf(fieldToOmit)

        if (indexToDelete !== -1) {
          required.splice(indexToDelete, 1)
        }
      }
    }

    if (declareRequired) {
      if (!('required' in definitions[schemaName])) {
        definitions[schemaName].required = []
      }

      for (const optionalField of declareRequired) {
        if (optionalField in properties) {
          definitions[schemaName]!.required!.push(optionalField)
        }
      }
    }

    return apiSchema
  }

  static buildValidationSchema(
    apiSchema: ApiSchema,
    additionalValidation: AsyncStepProps['additionalValidation'],
    translationsKey: AsyncStepProps['translationsKey']
  ): Yup.ObjectSchema<any> {
    const { definitions } = apiSchema
    const [schemaName] = Object.keys(definitions)
    const { properties, required = [] } = definitions[schemaName]
    const schemaShape: Record<string, any> = {}

    for (const field of required) {
      const { type, default: Default, maxLength, minLength } = properties[field]
      let fieldSchema: Yup.StringSchema<any> | Yup.AnyObjectSchema | null = null

      if (type.includes('string') || type.includes('integer')) {
        fieldSchema = Yup.string()
      }

      if (type.includes('array')) {
        fieldSchema = Yup.object().test(
          'select-age-range',
          translator.t('campaigns.ageRangeValidationMessage'),
          (value) => Object.values(value).some((val) => val === true)
        )
      }

      if (!fieldSchema) {
        throw new Error(`Unknown type support should be added to schema builder ${type}`)
      }

      if (fieldSchema instanceof Yup.StringSchema && maxLength) {
        fieldSchema = fieldSchema
          .max(maxLength, translate.t('common.validation.lengthMax', { length: maxLength }))
          .trim()
      }

      if (fieldSchema instanceof Yup.StringSchema && minLength) {
        fieldSchema = fieldSchema
          .min(minLength, translate.t('common.validation.lengthMin', { length: minLength }))
          .trim()
      }

      if (Default) {
        fieldSchema = fieldSchema?.default(Default)
      }

      if (type.includes('null')) {
        fieldSchema = fieldSchema?.nullable()
      }

      if (required.includes(field)) {
        fieldSchema = fieldSchema?.required(
          translate.t('common.validation.required', {
            field: translate.t(`${translationsKey}.fields.${field}`),
          })
        )
      }

      if (fieldSchema instanceof Yup.StringSchema && additionalValidation[field]) {
        fieldSchema = additionalValidation[field](fieldSchema)
      }

      schemaShape[field] = fieldSchema
    }

    return Yup.object(schemaShape)
  }

  static buildMarkupProps(
    apiSchema: ApiSchema,
    fieldsOrder: AsyncStepProps['fieldsOrder'],
    fieldsAdditionalProps: AsyncStepProps['fieldsAdditionalProps'] = {}
  ): Array<Partial<FormikInputProps>> {
    const { definitions } = apiSchema
    const [schemaName] = Object.keys(definitions)
    const { properties, required = [] } = definitions[schemaName]
    const sortedRequired = prioritizeProps(required, fieldsOrder)

    return sortedRequired.map((fieldName, index) => {
      let fieldProps: Partial<FormikInputProps> = {}
      const { maxLength } = properties[fieldName]

      if (index === 0) {
        fieldProps.autoFocus = true
      }

      fieldProps.name = fieldName
      fieldProps.required = true
      fieldProps.label = fieldName

      if (maxLength) {
        fieldProps.maxLength = maxLength
      }

      if (fieldName in fieldsAdditionalProps) {
        fieldProps = { ...fieldProps, ...fieldsAdditionalProps[fieldName] }
      }

      return fieldProps
    })
  }

  static buildInitialValues(apiSchema: ApiSchema, initialValues: AsyncStepProps['initialValues']): Record<string, any> {
    const { definitions } = apiSchema
    const [schemaName] = Object.keys(definitions)
    const { required = [] } = definitions[schemaName]
    const fields: Record<string, any> = {}

    for (const fieldName of required) {
      fields[fieldName] = initialValues[fieldName as keyof EnhancedCampaign]
    }

    return fields
  }
}
