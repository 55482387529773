import { createSelector, createSlice } from '@reduxjs/toolkit'
import type { IUserPreferences } from 'api/users/types'
import { UsersApi } from 'api/users/users'
import type { AppDispatch, RootState } from 'core/store/store'

export enum UserStatus {
  BLOCKED = 'blocked',
  ACTIVE = 'active',
}

export enum UserGender {
  MALE = 'male',
  FEMALE = 'female',
}

export enum Roles {
  superadmin = 'superadmin',
  admin = 'admin',
  employee = 'employee',
  // advertiser = 'advertiser'
}

export interface UserI {
  id: number
  name: string
  email: string
  emailVerified: boolean
  gender: UserGender
  role: Roles
  status: UserStatus
  tenant: string
  updatedAt: string
  createdAt: string
  preferences?: IUserPreferences
}

interface UserReducerInitialStateI {
  user: UserI
  isLoading: boolean
  error: string
}

const initialState: UserReducerInitialStateI = {
  user: {
    id: -1,
    email: '',
    emailVerified: false,
    gender: '' as any,
    name: '',
    status: UserStatus.BLOCKED,
    role: '' as Roles,
    tenant: '',
    updatedAt: '',
    createdAt: '',
    preferences: {
      language: '',
    },
  },
  isLoading: false,
  error: '',
}

const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLoading: (state) => {
      state.isLoading = true
    },
    userReset: () => initialState,
    userSuccess: (state, { payload }) => {
      state.isLoading = false
      state.user = payload
    },
    userFailure: (state) => {
      state.isLoading = false
    },
  },
})

export const { userFailure, userLoading, userSuccess, userReset } = userReducer.actions

export const userIsLoadingSelector = (state: RootState) => state.user.isLoading
export const userSelector = (state: RootState) => state.user.user
export const userRoleSelector = createSelector(userSelector, (user) => user.role)

export const getUser = (userId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(userLoading())

      const user = await UsersApi.getOne(userId)

      dispatch(userSuccess(user))
    } catch (e) {
      dispatch(userFailure())

      // eslint-disable-next-line no-console
      console.log('Failed to query user', e)
    }
  }
}

export default userReducer.reducer
