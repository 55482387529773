import { Routes } from 'core/router/routes'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ServiceDeskWidget = () => {
  const location = useLocation()

  useEffect(() => {
    try {
      const widgetFrameBody =
        // @ts-ignore
        document!.getElementById('jsd-widget')!.contentWindow.document.body

      if (window.location.href.endsWith(Routes.HELP)) {
        widgetFrameBody.removeAttribute('hidden')
      } else {
        widgetFrameBody.setAttribute('hidden', true)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Failed to display/hide widget or unable to find', e)
    }
  }, [location.pathname])

  return <></>
}

export default ServiceDeskWidget
