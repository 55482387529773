import type { StatusDetails } from 'core/type'

import { CampaignStatus } from '../../../../core/type'

export interface CampaignFailDetails {
  tabName?: string
  fieldNames?: Array<string>
  failedResource?: string
  failureMessage?: string
  failureCode?: string | number
}

export const failedStatusDetails = ({
  status,
  failedResource,
  failureMessage,
  failureCode,
}: StatusDetails): CampaignFailDetails => {
  if (!status || status !== CampaignStatus.failed) {
    return {}
  }
  // 8000 — unknown failure,
  // 8101 — invalid location, // ok
  // 8201 — invalid ad data, // ok
  // 8202 — invalid target URL, //
  // 8301 — invalid media file (mostly images), // ok
  // 8302 — invalid asset (mostly AMPHTML files). // ok

  switch (failureCode) {
    case 8000: {
      return {
        failureMessage,
        failureCode,
      }
    }
    case 8101: {
      return {
        tabName: 'targetingTab',
        fieldNames: ['locations'],
        failureMessage,
        failureCode,
      }
    }
    case 8201: {
      return {
        tabName: 'adTab',
        failureCode,
      }
    }
    case 8202: {
      return {
        tabName: 'adTab',
        fieldNames: ['url'],
        failureMessage,
        failureCode,
      }
    }
    case 8301: {
      return {
        tabName: 'adTab',
        fieldNames: ['images'],
        failedResource,
        failureMessage,
        failureCode,
      }
    }
    case 8302: {
      return {
        tabName: 'adTab',
        fieldNames: ['files'],
        failedResource,
        failureMessage,
        failureCode,
      }
    }

    default: {
      return {}
    }
  }
}
