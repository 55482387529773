import JSZip from 'jszip'
import type { HTMLElement as ParserHTMLElement } from 'node-html-parser'
import { parse } from 'node-html-parser'

import { getExtension } from '../../../pages/Campaigns/common/utils/AMPHTMLToImage'
import type { AMPHTMLParseResult } from '../../../pages/Campaigns/new/steps/preview/banner/amphtml'

const IMAGES_EXTENSIONS = {
  jpg: 'jpg',
  jpeg: 'jpeg',
  png: 'png',
  svg: 'svg+xml',
  bnp: 'bnp',
  gif: 'gif',
}
const SCRIPTS_NAME_REGEX = /js$/
const STYLES_NAMES_REGEX = /css$/

class AMPHTMLZIPParser {
  private parsedHTML = parse('<html></html>') // initial value stub
  private unzippedFile: JSZip = JSZip()

  constructor(private amphtmlZIP: File | Blob) {}

  public async transform(): Promise<AMPHTMLParseResult> {
    await this.unzip()
    await this.parseIndex()
    await this.proceedExternalResources()

    return {
      html: this.parsedHTML.toString(),
      ...this.getMeta(),
    }
  }

  private async unzip() {
    this.unzippedFile = await new JSZip().loadAsync(this.amphtmlZIP)
  }

  private async parseIndex() {
    const html = await this.unzippedFile.files['index.html'].async('string')
    this.parsedHTML = parse(html)
  }

  private async proceedExternalResources() {
    const unzippedFilesNames = Object.keys(this.unzippedFile.files)

    return Promise.all(unzippedFilesNames.map((file) => this.proceedExternalResource(file)))
  }

  private async proceedExternalResource(fileName: string) {
    if (getExtension(fileName)! in IMAGES_EXTENSIONS) {
      await this.proceedExternalImage(fileName)
    }

    if (SCRIPTS_NAME_REGEX.test(fileName)) {
      await this.proceedExternalScript(fileName)
    }

    if (STYLES_NAMES_REGEX.test(fileName)) {
      await this.proceedExternalStyle(fileName)
    }
  }

  private async proceedExternalScript(scriptFileName: string) {
    for (const scriptNode of this.parsedHTML.querySelectorAll(`script[src="${scriptFileName}"]`)) {
      scriptNode.textContent = await this.readFileAsString(scriptFileName)
      scriptNode.removeAttribute('src')
    }
  }

  private async proceedExternalStyle(fileName: string) {
    for (const linkNode of this.parsedHTML.querySelectorAll(`link[href="${fileName}"]`)) {
      linkNode.textContent = await this.readFileAsString(fileName)
      linkNode.removeAttribute('href')
    }
  }

  private async proceedExternalImage(srcUrl: string) {
    const documentImages = this.parsedHTML.querySelectorAll('img, amp-img')

    for (const imageNode of documentImages) {
      if (imageNode.getAttribute('src') === srcUrl) {
        await this.replaceImageSrc(imageNode, srcUrl)
      }
    }
  }

  private async replaceImageSrc(imageNode: ParserHTMLElement, fileName: string) {
    const imageBase64Src = await this.readFileAsBase64(fileName)

    imageNode.setAttribute('src', imageBase64Src)

    if (imageNode.hasAttribute('srcset')) {
      imageNode.removeAttribute('srcset')
    }
  }

  private async readFileAsBase64(fileName: string) {
    const imageBase64 = await this.unzippedFile.files[fileName].async('base64')

    const base64src = `data:image/${
      IMAGES_EXTENSIONS[getExtension(fileName) as keyof typeof IMAGES_EXTENSIONS]
    };base64, ${imageBase64}`

    return base64src
  }

  private async readFileAsString(fileName: string) {
    return this.unzippedFile.files[fileName].async('string')
  }

  private getMeta() {
    const metaWithAdSize: ParserHTMLElement = this.parsedHTML.querySelector('meta[name="ad.size"]')!

    const adSizes = metaWithAdSize
      .getAttribute('content')!
      .split(',')
      .reduce((cumulative, current) => {
        const [property, value] = current.split('=')

        return {
          ...cumulative,
          [property]: parseInt(value),
        }
      }, {})

    return adSizes as { width: number; height: number }
  }
}

export default AMPHTMLZIPParser
