import type { BoxProps } from '@material-ui/core'

import type { CampaignFailDetails } from '../../../pages/Campaigns/legacy/common/CampaignFailDetails'

interface ImageResolutionRequirements {
  width: number
  height: number
}

export enum IMAGE_GROUP {
  LOGO = 'logo',
  IMAGE = 'image',
}

export enum IMAGE_TYPE {
  // horizontal — The aspect ratio 1.91:1, minimum resolution 600×314 px,
  // polyvision— The aspect ratio 4:1, minimum resolution 512×128 px,
  // square — The aspect ratio 1:1, minimum resolution 300×300 px.
  HORIZONTAL = 'horizontal',
  POLYVISION = 'polyvision',
  SQUARE = 'square',
  RECTANGLE = 'rectangle',
}

export type TypedImageRequirements = ImageResolutionRequirements & {
  type: IMAGE_TYPE
}

export const IMAGE_RESTRICTIONS: Record<IMAGE_TYPE, TypedImageRequirements> = {
  [IMAGE_TYPE.HORIZONTAL]: {
    width: 600,
    height: 314,
    type: IMAGE_TYPE.HORIZONTAL,
  },
  [IMAGE_TYPE.POLYVISION]: {
    width: 512,
    height: 128,
    type: IMAGE_TYPE.POLYVISION,
  },
  [IMAGE_TYPE.SQUARE]: { width: 300, height: 300, type: IMAGE_TYPE.SQUARE },
  [IMAGE_TYPE.RECTANGLE]: {
    width: 300,
    height: 250,
    type: IMAGE_TYPE.RECTANGLE,
  },
}

export interface IImage {
  id: number
  url: string
  group: IMAGE_GROUP
  type: IMAGE_TYPE
}

export interface AddedImage {
  id: null | number
  url: string
  file: File
  keyStub: number
  group: IMAGE_GROUP
  type: IMAGE_TYPE
}

export interface ImageUploaderI {
  disabled?: boolean
  isRequired?: boolean
  disableReason?: string
  images: Array<IImage>
  maximumImages: number
  title?: string
  maxSize: number
  group: IMAGE_GROUP
  types: Array<IMAGE_TYPE>
  allowedResolutions: Array<TypedImageRequirements>
  setFieldValue: (field: string, images: Array<IImage | AddedImage>) => void
  setFieldTouched: (field: string) => void
  boxStyles?: BoxProps
  errorDetails?: CampaignFailDetails
}
