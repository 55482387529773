import { AppearanceService } from 'api/appearance/appearance'
import type { LogoInfo } from 'api/tenantAppearance/types'
import type { Appearance } from 'core/store/reducers/appearance'
import { themeLoaded, themeLoadedSelector, updateAppearance, updateCustomLogo } from 'core/store/reducers/appearance'
import type { FC } from 'react'
import { useEffect } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'

import { updateTenantTheme } from '../../core/store/reducers/tenantAppearance'

const AppearanceLoader: FC = ({ children }) => {
  const dispatch = useDispatch()
  const isThemeLoaded = useSelector(themeLoadedSelector)

  useEffect(() => {
    if (isThemeLoaded) {
      return
    }

    Promise.allSettled([AppearanceService.getOwnAppearance(), AppearanceService.getOwnLogo()])
      .then((results) => results.map((promise) => 'value' in promise && promise.value))
      .then((result) => {
        const [appearance, customLogo] = result as [Appearance, LogoInfo | undefined]

        batch(() => {
          dispatch(updateTenantTheme(appearance))
          dispatch(updateAppearance(appearance))

          if (customLogo) {
            dispatch(updateCustomLogo(customLogo.url))
          }

          dispatch(themeLoaded())
        })
      })
      // eslint-disable-next-line no-console
      .catch((e) => console.log('Error occurred during load appearance', e))
      .finally(() => {
        dispatch(themeLoaded())
      })
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [isThemeLoaded])

  return <>{children}</>
}

export default AppearanceLoader
