import { Typography } from '@material-ui/core'
import type { FC } from 'react'
import React from 'react'

export const CategoryHeader: FC<{ value: string }> = ({ value }) => {
  return (
    <Typography style={{ fontSize: 20 }} variant='h6' gutterBottom>
      {value}
    </Typography>
  )
}
