import axios from 'core/axios'
import type { Appearance } from 'core/store/reducers/appearance'

import { appearanceDeserializer, appearanceSerializer } from '../appearance/appearance'
import type { LogoInfo } from './types'

export class TenantAppearanceAPI {
  static getTenantLogo = async (id: string): Promise<LogoInfo | void> => {
    try {
      const { data } = await axios.get(`/tenants/${id}/logo`, {
        headers: { Accept: 'application/json' },
      })

      return data
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while creating getting logo', error)
      // this request does not throw error - logo can be absent
    }
  }

  static getTenantAppearance = async (id: string) => {
    try {
      const { data } = await axios.get(`/tenants/${id}/appearance`, {
        headers: { Accept: 'application/json' },
      })

      return appearanceDeserializer(data)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error while creating getting appearance', error)

      return Promise.reject(error)
    }
  }

  static updateTenantAppearance = async (appearance: Appearance, tenantId: string) => {
    try {
      const { data } = await axios.put(`/tenants/${tenantId}/appearance`, appearanceSerializer(appearance), {
        headers: { Accept: 'application/json' },
      })

      return appearanceDeserializer(data)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while creating setting appearance', error)

      return Promise.reject(error)
    }
  }

  static updateTenantLogo = async (file: File, id: string): Promise<{ url: string }> => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('tenant', `${id}`)
      const { data } = await axios.post('/logos', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      return data
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while creating setting appearance', error)

      return Promise.reject(error)
    }
  }
}
