import Axios from 'core/axios'

import type { PointCoordinates, PointCoordinatesRaw, PolygonsLocation } from '../../common/components/Maps'

const POINTS_TO_DRAW_CIRCLE = 5

export class MapsApi {
  static async getLocationPolygons(address: string): Promise<PolygonsLocation> {
    try {
      const response = await Axios.get(`maps/polygons?q=${address}`)
      const serialized = this.serializeLocations(response.data)

      if (serialized[0]?.length <= POINTS_TO_DRAW_CIRCLE) {
        return {
          displayAs: 'circle',
          coordinates: serialized,
          address,
        }
      }

      return {
        displayAs: 'polygon',
        coordinates: serialized,
        address,
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while querying location data', error)

      return {
        coordinates: [[]],
        displayAs: 'polygon',
        address,
      }
    }
  }

  private static serializeLocations = ({
    polygons,
  }: {
    polygons: Array<Array<PointCoordinatesRaw>>
  }): Array<Array<PointCoordinates>> => {
    return polygons.map((locationCoordinates) =>
      locationCoordinates.map(({ lon, lat }) => ({
        lat,
        lng: lon,
      }))
    )
  }
}
