import { Box } from '@material-ui/core'
import NoImage from 'assets/icons/no_image_banner_mobile.png'
import React, { useEffect } from 'react'

import { Mobile } from '../../devices/Mobile'
// @ts-ignore
import { BlockSimulation } from '../../devices/markupSimulation/block'

const CreateMobile = ({ imageUrl }: { imageUrl?: string }) => {
  useEffect(() => {
    const screen = document.querySelector('.screen')

    if (screen) {
      screen.setAttribute('style', 'overflow: hidden;background: white;')
      const screenChild = screen.querySelector('div')

      if (screenChild) {
        screenChild.setAttribute('style', 'position: absolute;width: 100%;')
      }
    }
  })

  return (
    <Mobile>
      <Box pr={1} pl={1} pt={2}>
        <Box p={5}>
          <img alt={'native preview'} src={imageUrl || NoImage} />
        </Box>
        <Box mt={4} mb={4} ml={2} mr={2}>
          <BlockSimulation height={20} />
        </Box>
        <Box mb={2} ml={2} mr={2}>
          <BlockSimulation />
        </Box>
        <Box mb={4} ml={2} mr={2}>
          <BlockSimulation />
        </Box>

        <Box ml={2} mr={2}>
          <BlockSimulation height={500} />
        </Box>
      </Box>
    </Mobile>
  )
}

export default CreateMobile
