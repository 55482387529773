import type { ILocation } from 'common/components/Maps'
import _ from 'lodash'

import { MAXIMUM_LOCATIONS_QUANTITY } from '../../pages/Campaigns/legacy/edit/schema'
import { LocationAPI } from './locations'

type RecreateParams<CreatedLocation> = {
  locationsToCreate: Array<CreatedLocation>
  locationsToDelete: Array<CreatedLocation>
}

export class LocationsWorker {
  public static recreate(params: RecreateParams<ILocation>) {
    const withDeleteSimulated = this.simulateDelete(params)
    const mergedLocations = this.mergeLocations(withDeleteSimulated)
    const { locationsToCreate, locationsToDelete } = this.makeEqualArrays(mergedLocations)

    const pairwiseLocations = this.makePairwise(locationsToCreate, locationsToDelete)

    return this.updateLocations(pairwiseLocations)
  }

  private static simulateDelete({
    locationsToDelete,
    locationsToCreate,
  }: RecreateParams<ILocation>): RecreateParams<ILocation> {
    return {
      locationsToCreate,
      locationsToDelete,
    }
  }

  private static makeEqualArrays({
    locationsToDelete,
    locationsToCreate,
  }: Pick<RecreateParams<ILocation>, 'locationsToCreate' | 'locationsToDelete'>): Pick<
    RecreateParams<ILocation | null>,
    'locationsToCreate' | 'locationsToDelete'
  > {
    return {
      locationsToDelete: this.makeEqualArray(locationsToDelete, locationsToCreate),
      locationsToCreate: this.makeEqualArray(locationsToCreate, locationsToDelete),
    }
  }

  private static updateLocations(
    pairwiseLocations: Array<[ILocation | null | undefined, ILocation | null | undefined]>
  ): Promise<any> {
    if (pairwiseLocations.length >= MAXIMUM_LOCATIONS_QUANTITY / 2) {
      return this.updateOneByOne(pairwiseLocations)
    } else {
      return this.updateAll(pairwiseLocations)
    }
  }

  private static async updateOneByOne(
    pairwiseLocations: Array<[ILocation | null | undefined, ILocation | null | undefined]>
  ) {
    for (const pair of pairwiseLocations) {
      await this.proceedPair(pair)
    }
  }
  private static updateAll(pairwiseLocations: Array<[ILocation | null | undefined, ILocation | null | undefined]>) {
    return Promise.all(pairwiseLocations.map((pair) => this.proceedPair(pair)))
  }

  private static proceedPair(
    pair: [ILocation | null | undefined, ILocation | null | undefined]
  ): Promise<ILocation | void> {
    const [toCreate, toDelete] = pair

    if (toCreate && toDelete) {
      return LocationAPI.locationDeserializer({
        ...toCreate,
        id: toDelete.id!,
      })
    }

    if (!toCreate && toDelete) {
      return LocationAPI.delete(toDelete.id!)
    }

    if (toCreate && !toDelete) {
      return LocationAPI.create(toCreate as ILocation)
    }

    return Promise.resolve()
  }

  private static mergeLocations({
    locationsToDelete,
    locationsToCreate,
  }: RecreateParams<ILocation>): Pick<RecreateParams<ILocation>, 'locationsToCreate' | 'locationsToDelete'> {
    return {
      locationsToCreate: [...locationsToCreate],
      locationsToDelete: [...locationsToDelete],
    }
  }

  private static makePairwise(
    createLocations: Array<ILocation | null>,
    deleteLocations: Array<ILocation | null>
  ): Array<[ILocation | null | undefined, ILocation | null | undefined]> {
    return _.zip(createLocations, deleteLocations)
  }

  private static makeEqualArray(
    prependArray: Array<ILocation>,
    comparisonArray: Array<ILocation>
  ): Array<ILocation | null> {
    const biggerArrayLength =
      prependArray.length > comparisonArray.length ? prependArray.length : comparisonArray.length
    const smallerArrayLength =
      prependArray.length < comparisonArray.length ? prependArray.length : comparisonArray.length
    const arraysDiff = biggerArrayLength - smallerArrayLength
    const itemsToPrepend = arraysDiff - 1 > 0 ? arraysDiff - 1 : arraysDiff

    return new Array(itemsToPrepend).fill(null).concat(...prependArray)
  }
}
