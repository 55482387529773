import { CircularProgress, FormHelperText, Grid, IconButton, Typography } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { CloseRounded } from '@material-ui/icons'
import { Card } from '@mui/material'
import { LocationSearchInput } from 'common/components/Maps/Components/LocationSearchInput'
import { LocationTypes } from 'core/type'
import type { useFormik } from 'formik'
import { MAXIMUM_LOCATIONS_QUANTITY } from 'pages/Campaigns/legacy/edit/schema'
import type { ReactNode } from 'react'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Suggestion } from 'react-places-autocomplete'

import { LocationAPI } from '../../../../api/locations/locations'
import { RadiusChooseSlider } from '../../Sliders/RadiusChooseSlider'
import type { ILocation } from '../index'

const useStyles = makeStyles((theme: Theme) => ({
  MuiCard: {
    padding: '16px 26px',
    margin: '16px 0',
    borderRadius: '4px',
    border: '1px solid #E0E0E0',
    overflow: 'visible',
    '&:first-child': {
      marginTop: 0,
    },
  },
  selectorContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  removeButton: {
    padding: 0,
  },
  MuiChip: {
    backgroundColor: theme.palette.primary[50],
    color: theme.palette.primary.main,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary[100],
    },
    textOverflow: 'ellipsis',
    maxWidth: 400,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: 5,
    margin: '0px 5px 5px 5px',
  },
  marginRight: {
    marginRight: '0.5rem',
  },
  activeButton: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  clickable: {
    cursor: 'pointer',
  },
  loading: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'center',
  },
}))

interface Props {
  formik: Pick<
    ReturnType<typeof useFormik>,
    'setFieldTouched' | 'errors' | 'touched' | 'setFieldValue' | 'values' | 'setSubmitting' | 'isSubmitting'
  >
  focusInitially?: boolean
  disabled?: boolean
}

export const LocationsSection = ({ formik, focusInitially, disabled }: Props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { setFieldValue, touched, setFieldTouched, errors, values } = formik
  const locationsFromFormik = values.locations as Array<ILocation>
  const [isLoading, setLoading] = useState<boolean>(false)

  const Loader = useMemo(() => {
    return (
      isLoading && (
        <div className={classes.loading}>
          <CircularProgress data-testid='loader' disableShrink thickness={5} />
        </div>
      )
    )
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [isLoading])

  const addAddress = async (address: string, suggestion: Suggestion) => {
    if (
      locationsFromFormik.some(
        (existingLocation) =>
          existingLocation.address === suggestion.description ||
          existingLocation.enteredAddress === suggestion.description
      )
    ) {
      return
    }

    setLoading(true)

    const addressDetails = await LocationAPI.getNewLocationDetails(suggestion, address)

    const newAddress: ILocation = {
      ...addressDetails,
      type: LocationTypes.ADDRESS,
      id: null,
    }
    // TODO remove this code and add check to existing address like the one to be added
    setFieldValue('locations', [newAddress, ...locationsFromFormik])
    setLoading(false)
  }

  const removeAddress = (idx: number) => {
    setFieldValue(
      'locations',
      locationsFromFormik.filter((_, index) => index !== idx)
    )
  }

  const changeRadius = (idx: number, radius: number) => {
    const [location] = locationsFromFormik.filter((_, index) => index === idx)
    const newLocations = [...locationsFromFormik]
    newLocations[idx] = { ...location, radius }

    setFieldValue('locations', newLocations) /// TODO THROTTLE THIS
  }

  const AddressTypeSelector = (location: ILocation): ReactNode => {
    const cx = (...list: Array<any>): string => list.filter(Boolean).join(' ') // TODO REMOVE this

    const handleAddressTypeChange = (newType: LocationTypes) => {
      if (location.type === newType) {
        return
      }

      const locationIndex = locationsFromFormik.findIndex((l) => l === location)
      const updatedLocation = {
        ...location,
        radius: location.radius || 30, // TODO replace all 30 to constant
        type: newType,
      }
      const newLocations = [...locationsFromFormik]
      newLocations[locationIndex] = updatedLocation

      setFieldValue('locations', newLocations)
    }

    return (
      !disabled && (
        <>
          <Typography
            variant='body1'
            onClick={() => handleAddressTypeChange(LocationTypes.ADDRESS)}
            className={cx(
              location.type === LocationTypes.ADDRESS ? classes.activeButton : classes.clickable,
              classes.marginRight
            )}
          >
            {t('campaigns.locationType.specificArea')}
          </Typography>

          <Typography
            variant='body1'
            onClick={() => handleAddressTypeChange(LocationTypes.POINT)}
            className={cx(
              location.type === LocationTypes.POINT ? classes.activeButton : classes.clickable,
              classes.marginRight
            )}
          >
            {t('campaigns.locationType.radius')}
          </Typography>
        </>
      )
    )
  }

  return (
    <>
      <>
        {!disabled && (
          <LocationSearchInput
            error={!!formik.errors.locations}
            disabled={locationsFromFormik.length >= MAXIMUM_LOCATIONS_QUANTITY}
            isPoint={true}
            addLocation={addAddress}
            setTouched={(value: boolean) => setFieldTouched('locations', value, true)}
            focusInitially={focusInitially}
          />
        )}
        {touched.locations && errors.locations && (
          <FormHelperText error>{errors.locations && t('common.validation.locationsWereNotProvided')}</FormHelperText>
        )}
      </>
      {Loader}
      <>
        {locationsFromFormik.map((location, idx) => {
          const { address, enteredAddress, lng, lat } = location
          const displayName = enteredAddress || address || `Unknown location latitude:${lat}, longitude:${lng} `

          return (
            <Card key={idx} className={classes.MuiCard} elevation={0}>
              <Grid container alignItems='center' justifyContent='space-between'>
                <Grid item xs={7}>
                  <Typography variant='subtitle1'>{displayName}</Typography>
                </Grid>
                <Grid item xs={5} className={classes.selectorContainer}>
                  {AddressTypeSelector(location)}
                  {!disabled && (
                    <IconButton
                      data-testid={`delete-location-${location.address}`}
                      className={classes.removeButton}
                      onClick={() => removeAddress(idx)}
                    >
                      <CloseRounded />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
              {location.type === LocationTypes.POINT && (
                <RadiusChooseSlider
                  disabled={disabled}
                  value={location.radius!}
                  onChange={(newValue: number) => changeRadius(idx, newValue)}
                />
              )}
            </Card>
          )
        })}
      </>
    </>
  )
}
