export const isSpecifiedDateLessThanCurrent = (specified: string | Date | null) => {
  if (!specified) {
    return false
  }

  const current = Date.now()
  const provided = new Date(specified.toString()).getTime()

  return current > provided
}
