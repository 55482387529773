import { Box, Grid, Link, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import useStyles from './styles'

export const PrivateRoutesFooter = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <footer className={classes.root}>
      <Box className={classes.container}>
        <Grid container justifyContent={'space-between'}>
          <Box display={'flex'}>
            <Typography>© 2021-{new Date().getFullYear()} AdOnce by</Typography>{' '}
            <Link className={classes.link} href={'https://www.adonce.io/datenschutz'} target={'_blank'}>
              <Typography className={classes.companyName} color={'primary'}>
                {' '}
                Yeew GmbH
              </Typography>
            </Link>
          </Box>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Link className={classes.link} href={'https://www.adonce.io/datenschutz'} target={'_blank'}>
              <Typography className={classes.companyName} color={'primary'}>
                {t('footer.privacyPolicy')}
              </Typography>
            </Link>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Box className={classes.dot}></Box>
            </Box>
            <Link className={classes.link} href={'https://www.adonce.io/impressum'} target={'_blank'}>
              <Typography className={classes.companyName} color={'primary'}>
                {t('footer.imprint')}
              </Typography>
            </Link>
          </Box>
        </Grid>
      </Box>
    </footer>
  )
}
