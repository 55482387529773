import type { IStrategy } from './TenantStrategyAPI'
import { TenantStrategyAPI } from './TenantStrategyAPI'

export const toEuroCents = (sum: number) => +(sum * 100).toFixed(0)
export const fromEuroCents = (sum: number) => sum / 100

export class TenantStrategiesService {
  updateDefaultStrategy = async (strategyId: number) => {
    try {
      await TenantStrategyAPI.setDefaultStrategy(strategyId)
    } catch (error) {
      //eslint-disable-next-line no-console
      console.log('error while update tenant strategy')

      return Promise.reject(error)
    }
  }

  updateStrategyValue = async (strategyId: number, strategy: IStrategy) => {
    try {
      const { bidValue } = strategy

      await TenantStrategyAPI.updateValue(strategyId, { bidValue })
    } catch (error) {
      //eslint-disable-next-line no-console
      console.log('error while update tenant strategy')

      return Promise.reject(error)
    }
  }

  get = async (campaignId: number | string) => {
    return TenantStrategyAPI.get(campaignId)
  }
}

export const TenantStrategyServiceAPI = new TenantStrategiesService()
