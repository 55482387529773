import { ThemeProvider } from '@material-ui/core/styles'
import { getTheme } from 'core/palette'
import { appearanceSelector } from 'core/store/reducers/appearance'
import React from 'react'
import { useSelector } from 'react-redux'

interface ThemeProviderWrapperProps {
  children: React.ReactNode
}

const ThemeProviderWrapper: React.FC<ThemeProviderWrapperProps> = ({ children }) => {
  const { theme, baseColor } = useSelector(appearanceSelector)
  const customizedTheme = getTheme(theme, baseColor)

  return <ThemeProvider theme={customizedTheme}>{children}</ThemeProvider>
}

export default ThemeProviderWrapper
