import { Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SmsFailedRoundedIcon from '@material-ui/icons/SmsFailedRounded'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { campaignSelector } from '../../../../core/store/reducers/campaign'
import { selectedTenantSelector } from '../../../../core/store/reducers/dropdownTenants'

const useReportStyles = makeStyles(() => ({
  wrapper: {
    zIndex: 1000,
    position: 'fixed',
    right: 24,
    bottom: 50,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(20, 150, 0, 1)',
    width: 174,
    height: 40,
    borderRadius: '24px',
  },
  link: {
    textDecoration: 'none',
    color: 'white',
  },
  text: {
    marginLeft: 8,
    fontSize: 14,
    fontWeight: 600,
    fontStyle: 'normal',
  },
  icon: {
    width: 20,
    height: 20,
  },
}))

const emailAddress = process.env.REACT_APP_REPORT_EMAIL || 'kampagnenmanagement@adonce.io'

const IssueReportButton: React.FC = ({ children }) => {
  const reportStyles = useReportStyles()
  const { name } = useSelector(campaignSelector)
  const { t } = useTranslation()
  const selectedTenant = useSelector(selectedTenantSelector)
  const selectedTenantInfo = selectedTenant ? t('reportMail.tenantInfo', { tenantName: selectedTenant?.name }) : ''

  return (
    <>
      <Box className={reportStyles.wrapper}>
        <address>
          <a
            className={reportStyles.link}
            href={`mailto:${emailAddress}?subject=${t('reportMail.subject', {
              campaignName: name,
              tenantName: selectedTenantInfo,
            })}&body=${t('reportMail.body', {
              campaignName: name,
              campaignLink: window.location.href,
              tenantName: selectedTenantInfo,
            })}`}
          >
            <Grid className={reportStyles.button}>
              <SmsFailedRoundedIcon color={'inherit'} className={reportStyles.icon} />
              <Box className={reportStyles.text}>{t('common.reportButton')}</Box>
            </Grid>
          </a>
        </address>
      </Box>
      {children}
    </>
  )
}

export default IssueReportButton
