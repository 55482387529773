import { getCurrentLocaleConfig } from 'core/router/hooks/useLocales'

export const formatCurrency = (amount: number) => {
  const validatedAmount = validateNumber(amount)

  return new Intl.NumberFormat(getCurrentLocaleConfig().fullLocaleName, {
    style: 'currency',
    currency: 'EUR',
  }).format(validatedAmount)
}

const validateNumber = (num: number) => {
  if (num && isFinite(num) && !isNaN(num)) {
    return num
  } else {
    return 0
  }
}
