import { Box, Grid } from '@material-ui/core'
import type { BoxProps } from '@material-ui/core/Box/Box'
import { makeStyles } from '@material-ui/core/styles'
import type { FC } from 'react'

const useStyles = makeStyles(() => {
  return {
    container: {
      boxShadow: '0px 1px 0px 0px #10182829',
      position: 'relative',
      '&:before': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        content: '""',
        left: '-100%',
        width: '100%',
        height: '100%',
        background: 'inherit',
        boxShadow: '0px 1px 0px 0px #10182829',
      },
      '&:after': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        content: '""',
        right: '-100%',
        width: '100%',
        height: '100%',
        background: 'inherit',
        boxShadow: '0px 1px 0px 0px #10182829',
      },
    },
  }
})

export const NavigationContainer: FC<{ bgColor?: string; containerProps?: BoxProps }> = ({
  children,
  bgColor = 'white',
  containerProps = { pt: 6, pb: 6, minHeight: '88px' },
}) => {
  const { container } = useStyles()

  return (
    <Box className={container} style={{ background: bgColor, minHeight: '88px' }}>
      <Grid container>
        <Grid item xs={12}>
          <Box {...containerProps}>{children}</Box>
        </Grid>
      </Grid>
    </Box>
  )
}
