import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import NoImage from 'assets/icons/no_image_native_desktop_horizontal.png'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import type { AddedImage, IImage } from '../../../../../../common/components/Images/types'
import { IMAGE_GROUP, IMAGE_TYPE } from '../../../../../../common/components/Images/types'
import { filterByGroup, filterByType } from '../../advertising/native/schema'
import { Desktop } from '../devices/Desktop'
import { BlockSimulation } from '../devices/markupSimulation/block'
import type { IEnhancedCampaign } from './DesktopSquare'

const styles = makeStyles(() => ({
  headline: {
    fontSize: 14,
    fontWeight: 600,
    textAlign: 'start',
  },
  description: {
    fontWeight: 400,
    fontSize: 13,
    textAlign: 'start',
    fontFamily: 'Source Sans Pro',
    whiteSpace: 'pre-wrap',
  },
  businessName: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'start',
    fontFamily: 'Source Sans Pro',
    color: 'rgba(0, 0, 0, 0.6)',
  },
}))

const DesktopHorizontal = ({ campaign, activeImageIndex }: IEnhancedCampaign) => {
  const classes = styles()
  const { headline, description, businessName } = campaign.ad
  const { t } = useTranslation()

  const image = filterByType(
    filterByGroup(campaign.images as Array<IImage | AddedImage>, IMAGE_GROUP.IMAGE),
    IMAGE_TYPE.HORIZONTAL
  )[activeImageIndex]

  useEffect(() => {
    const screen = document.querySelector('.screen')
    const screenFirstDescendant = document.querySelector('.screen>div')

    if (screen) {
      screen.setAttribute('style', 'overflow: hidden;background: white;')
    }

    if (screenFirstDescendant) {
      screenFirstDescendant.setAttribute('style', 'position: absolute; width: 100%;')
    }
  })

  return (
    <Desktop>
      <Box p={12}>
        <BlockSimulation height={20} />
        <Box mb={6} />
        <BlockSimulation height={20} />
        <Box mb={6} />
        <BlockSimulation width={'20%'} height={20} />
        <Box mb={6} />

        <Grid container spacing={6}>
          <Grid item xs={6}>
            <img alt={'native preview'} src={image?.url || NoImage} />
          </Grid>
          <Grid item xs={6}>
            <Box mt={4} mb={4}>
              <Typography variant='subtitle2' color='textPrimary' className={classes.headline}>
                {headline || t('campaigns.preview.addHeadline')}
              </Typography>
              <Box mt={2}>
                <Typography variant='subtitle2' color='textPrimary' className={classes.description}>
                  {description || t('campaigns.preview.addDescription')}
                </Typography>
              </Box>
              <Box mt={3}>
                <Typography variant='subtitle2' color='textPrimary' className={classes.businessName}>
                  {businessName || t('campaigns.preview.addBusinessName')}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box mb={6} />
        <BlockSimulation height={20} />
        <Box mb={6} />
        <BlockSimulation width={'50%'} height={20} />
        <Box mb={6} />
        <BlockSimulation height={500} />
      </Box>
    </Desktop>
  )
}

export default DesktopHorizontal
