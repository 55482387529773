import { createSlice } from '@reduxjs/toolkit'
import { ThemeNames, getTheme } from 'core/palette'
import type { RootState } from 'core/store/store'

export interface Appearance {
  theme: ThemeNames
  baseColor: string
  isPoweredBy: boolean
  isCustomLogo: boolean
  themeLoaded?: boolean
}

export interface CustomLogo {
  customLogoUrl: string
}

const DEFAULT_THEME = ThemeNames.indigo

export const initialState: Appearance & CustomLogo = {
  theme: DEFAULT_THEME,
  baseColor: getTheme(DEFAULT_THEME).palette.primary.main,
  isPoweredBy: false,
  isCustomLogo: false,
  customLogoUrl: '',
  themeLoaded: false,
}

const appearance = createSlice({
  name: 'appearance',
  initialState,
  reducers: {
    updateTheme: (state, { payload }) => {
      const { theme, baseColor } = payload
      state.theme = theme
      state.baseColor = baseColor
    },
    updateAppearance: (state, { payload }) => {
      const { theme, baseColor, isPoweredBy, isCustomLogo } = payload
      state.theme = theme
      state.baseColor = baseColor
      state.isPoweredBy = isPoweredBy
      state.isCustomLogo = isCustomLogo
    },
    themeLoaded: (state) => {
      state.themeLoaded = true
    },
    updateCustomLogo: (state, { payload }) => {
      state.customLogoUrl = payload
    },
  },
})

export default appearance.reducer

export const { updateAppearance, updateCustomLogo, updateTheme, themeLoaded } = appearance.actions

export const appearanceSelector = (state: RootState) => state.appearance
export const themeLoadedSelector = (state: RootState) => state.appearance.themeLoaded
