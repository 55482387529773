// @ts-ignore
import { Box, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, createStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import type { EnhancedCampaign } from 'core/type'
import translate from 'i18next'
import type { FC } from 'react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import HandleImagesDeviceType from '../../common/HandleImagesDeviceType'

export type Device = 'mobile' | 'desktop'

export type PreviewVariant = 'wizard' | 'tab'

interface Props {
  campaign: EnhancedCampaign
}

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      fontWeight: 600,
      fontSize: 20,
      color: 'rgba(0, 0, 0, 0.88)',
      marginBottom: 10,
    },
  })
)

const usePreviewStyles = makeStyles({
  previewContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    width: 417,
    flexWrap: 'wrap',
    gap: 8,
  },
  title: {
    marginTop: 32,
    fontWeight: 600,
    fontSize: 20,
    color: 'rgba(0, 0, 0, 0.88)',
  },
  imageBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 92,
    height: 78,
  },
  image: {
    width: 84,
    height: 70,
    borderRadius: 4,
    border: '1px solid var(--grey-100, #D8DAD9)',
    cursor: 'pointer',
  },
  imageActive: {
    width: 92,
    height: 78,
    padding: 2,
    borderRadius: 8,
    border: '2px solid  #149600',
    cursor: 'pointer',
  },
})

const BannerContent: FC<Props> = ({ campaign }) => {
  const { images, devices } = campaign
  const { label } = useStyles()
  const { t } = useTranslation()
  const classes = usePreviewStyles()
  const [deviceType, setDeviceType] = useState<Device>(devices.desktop ? 'desktop' : 'mobile')
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0)
  const selectedImageUrl = images.length > 0 ? images[activeImageIndex].url : null // TODO to useMemo or directly as call

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeviceType((event.target as HTMLInputElement).value as Device)
  }

  return (
    <Box mt={2}>
      <Grid container spacing={2} justifyContent={'flex-start'} direction={'row'}>
        <Grid item xs={6}>
          <FormControl component='fieldset'>
            <FormLabel component='legend' className={label}>
              {t('campaigns.preview.selectDevice')}
            </FormLabel>
            <RadioGroup row aria-label='device' name='device' value={deviceType} onChange={handleChange}>
              <FormControlLabel
                value='desktop'
                disabled={!devices.desktop}
                control={<Radio color='primary' />}
                label={t('campaigns.preview.desktop')}
              />
              <FormControlLabel
                value='mobile'
                disabled={!devices.mobile}
                control={<Radio color='primary' />}
                label={t('campaigns.preview.mobile')}
              />
            </RadioGroup>
          </FormControl>
          <FormLabel component='legend' className={classes.title}>
            {t('campaigns.preview.selectImages')}
          </FormLabel>
          <Box className={classes.previewContainer}>
            {images.map((image, index) => {
              const className = activeImageIndex === index ? classes.imageActive : classes.image

              return (
                <Box key={index} className={classes.imageBox}>
                  <img className={className} src={image.url} alt='preview' onClick={() => setActiveImageIndex(index)} />
                </Box>
              )
            })}
          </Box>
        </Grid>
        <Grid item xs={6}>
          {selectedImageUrl && HandleImagesDeviceType({ deviceType, selectedImageUrl })}
        </Grid>
      </Grid>
    </Box>
  )
}

export const CampaignImagesBannerTab: FC<{ campaign: EnhancedCampaign }> = ({ campaign }) => (
  <BannerContent campaign={campaign} />
)

export const WizardImagesBannerStep = (campaign: EnhancedCampaign) => {
  return {
    isSavedOnPrev: true,
    Content: <BannerContent campaign={campaign} />,
    isValid: true,
    isSubmitting: false,
    isValidating: false,
    errors: {},
    label: translate.t('campaigns.preview.preview'),
    submit: () => Promise.resolve(true),
    dirty: false,
    isOptional: true,
    resetForm: () => Promise.resolve(),
    isShown: true,
  }
}
