import { Button, createStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import type { FC } from 'react'

import { LinkUnstyled } from '../Links/LinkUnstyled'

const useButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
      textTransform: 'none',
      background: 'inherit',
      fontWeight: 400,
      color: '#D8DAD9',
      letterSpacing: '0.5px',
      '& svg': {
        fill: '#B1B5B3',
      },
      '&:hover': {
        backgroundColor: 'inherit',
        color: '#B1B5B3',
        '& svg': {
          fill: '#8B918D',
        },
      },
      '&:focus': {
        backgroundColor: 'inherit',
        color: '#B1B5B3',
        '& svg': {
          fill: '#8B918D',
        },
      },
    },
    disabled: {
      color: '#FFFFFF !important',
      fontWeight: 600,
      background: '#313934 !important',
      '& svg': {
        fill: '#FFFFFF !important',
      },
    },
  })
)

export const NavigationButton: FC<{ link: string; text: string; icon: any; queryParams?: string }> = ({
  link,
  text,
  icon: Icon,
  queryParams,
}) => {
  const classes = useButtonStyles()
  const disabled = window.location.pathname.includes(link)

  const encodedQueryParams = encodeURI(queryParams!)

  return (
    <LinkUnstyled to={link + (queryParams ? encodedQueryParams : '')}>
      <Button
        style={{ boxShadow: 'none' }}
        variant='contained'
        className={disabled ? classes.disabled : ''}
        color='secondary'
        classes={classes}
        startIcon={<Icon />}
      >
        {text}
      </Button>
    </LinkUnstyled>
  )
}
