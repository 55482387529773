import { TenantAPI } from 'api/tenants/tenants'
import { FullscreenLoader } from 'common/components/Loaders/FullscreenLoader'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { loggedUserRoleSelector } from '../../../core/store/reducers/auth'
import { selectedTenantSelector, tenantsLoading, tenantsSuccess } from '../../../core/store/reducers/dropdownTenants'
import { Roles } from '../../../core/store/reducers/userReducer'

export const TenantsLoader: FC = ({ children }) => {
  const [isLoading, setLoading] = useState<boolean>(true)

  const role = useSelector(loggedUserRoleSelector)
  const selectedTenant = useSelector(selectedTenantSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (selectedTenant) {
      setLoading(false)

      return
    }

    if (role && role !== Roles.superadmin) {
      setLoading(false)
    }

    if (role === Roles.superadmin) {
      dispatch(tenantsLoading())

      TenantAPI.getAll()
        .then((result) => dispatch(tenantsSuccess(result.data)))
        .catch((e) => {
          // eslint-disable-next-line no-console
          return console.log(e)
        })
        .finally(() => setLoading(false))
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [role])

  return <>{isLoading ? <FullscreenLoader from={'Tenants loader'} /> : children}</>
}
