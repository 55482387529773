export const BlockSimulation = ({ height = 14, width = 'auto' }: { height?: number; width?: number | string }) => {
  return (
    <div
      style={{
        width,
        height,
        background: '#EDEDED',
        borderRadius: 20,
      }}
    />
  )
}
