import axios from 'core/axios'
import type { AdServersI } from 'core/store/reducers/dropdownTenants'

import { TCampaignType } from '../../core/type'

const adServersSerializer = (settings: Partial<AdServersI>) => {
  const serversCount = Object.values(settings).filter((val) => !!val).length

  return Object.fromEntries(Object.entries(settings).map(([key, val]) => [key, val ? 100 / serversCount : 0]))
}

export class AdServersAPI {
  public static async getAdServers(): Promise<Array<TCampaignType>> {
    const { data } = await axios.get('ad-servers')
    const { googleAds, seedingAlliance } = data

    const adServers: Array<TCampaignType> = []

    if (googleAds && googleAds > 0) {
      adServers.push(TCampaignType.banner)
    }

    if (seedingAlliance && seedingAlliance > 0) {
      adServers.push(TCampaignType.native)
    }

    return adServers
  }

  public static async setAdServers(tenantId: string | number, settings: Partial<AdServersI>) {
    return axios.put(`tenants/${tenantId}/ad-servers`, adServersSerializer(settings))
  }
}
