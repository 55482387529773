import type { EnhancedCampaign } from 'core/type'
import { AdAttachmentType, TCampaignType } from 'core/type'
import { useSelector } from 'react-redux'

import { campaignSelector } from '../../../../../core/store/reducers/campaign'
import { CampaignAMPHTMLBannerTab, WizardAMPHTMLBannerStep } from './banner/amphtml'
import { CampaignImagesBannerTab, WizardImagesBannerStep } from './banner/images'
import { CampaignNativeTab, WizardNativeStep } from './native'

export const PreviewStep = () => {
  const campaign = useSelector(campaignSelector)

  switch (campaign.type) {
    case TCampaignType.banner: {
      switch (campaign.adAttachmentType) {
        case AdAttachmentType.AMPHTML: {
          return WizardAMPHTMLBannerStep(campaign)
        }

        default: {
          return WizardImagesBannerStep(campaign)
        }
      }
    }

    case TCampaignType.native: {
      return WizardNativeStep(campaign)
    }

    default: {
      // handle no image type
      return WizardNativeStep(campaign)
    }
  }
}

export const TabPreview = (campaign: EnhancedCampaign) => {
  switch (campaign.type) {
    case TCampaignType.banner: {
      switch (campaign.adAttachmentType) {
        case AdAttachmentType.AMPHTML: {
          return <CampaignAMPHTMLBannerTab campaign={campaign} />
        }

        default: {
          return <CampaignImagesBannerTab campaign={campaign} />
        }
      }
    }

    case TCampaignType.native: {
      return <CampaignNativeTab campaign={campaign} />
    }

    default: {
      // handle no image type
      return <CampaignImagesBannerTab campaign={campaign} />
    }
  }
}
