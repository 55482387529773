import type { FC } from 'react'
import React from 'react'

import type { AMPHTMLParseResult, Device } from '../banner/amphtml'
import Desktop from '../banner/amphtml/Desktop'
import Mobile from '../banner/amphtml/Mobile'

interface Props {
  deviceType: Device
  parseResult?: AMPHTMLParseResult
}

const HandleAMPHTMLDeviceType: FC<Props> = ({ deviceType, parseResult }) => {
  // To Component </>
  if (deviceType === 'desktop') {
    return <Desktop parseResult={parseResult} />
  } else {
    return <Mobile parseResult={parseResult} />
  }
}

export default HandleAMPHTMLDeviceType
