import { Box, Button, Dialog, Grid, Typography } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import translator from 'i18next'
import React, { Component } from 'react'

type DialogTextElement = string | React.ReactNode

interface SimpleDialogI {
  header: DialogTextElement
  body: DialogTextElement
  actionButton: DialogTextElement
  proceedButtonText: DialogTextElement
  cancelButtonText?: DialogTextElement
  onProceed: () => void
  onCancel?: () => void
  windowStyles?: React.CSSProperties
  actionButtonStyles?: React.CSSProperties
  proceedButtonStyles?: React.CSSProperties
  proceedHeaderCloseSelector?: string
  disabled?: boolean
  disabledProceedButton?: boolean
  loadingMarginBottom?: number
  shouldCloseBeConfirmed?: boolean
}

export class ButtonWithDialog extends Component<SimpleDialogI, { isOpened: boolean; isLoading: boolean }> {
  state = { isOpened: false, isLoading: false }

  handleOpenDialog = () => {
    if (!this.props.disabled) {
      this.setState({ isOpened: true })
    }
  }

  handleClose = () => {
    if (this.props.shouldCloseBeConfirmed) {
      if (!window.confirm(translator.t('campaigns.note.question'))) {
        return
      }
    }
    this.setState({ isOpened: false })
  }

  handleCancel = () => {
    const { onCancel } = this.props

    if (onCancel) {
      onCancel()
    }
    this.handleClose()
  }

  handleProceed = async () => {
    const { onProceed } = this.props

    this.setState({ isLoading: true })
    await onProceed()
    this.setState({ isOpened: false, isLoading: false })
  }

  handleHeaderCloseButton = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (this.props.proceedHeaderCloseSelector) {
      const closeButton = document.getElementById(this.props.proceedHeaderCloseSelector)

      // @ts-ignore
      if (e.target === closeButton || closeButton?.contains(e.target)) {
        this.handleClose()
      }
    }
  }

  render() {
    const {
      body,
      header,
      cancelButtonText,
      proceedButtonText,
      actionButton,
      windowStyles,
      actionButtonStyles,
      proceedButtonStyles,
      disabledProceedButton,
      loadingMarginBottom = 5,
    } = this.props

    const { isLoading, isOpened } = this.state

    return (
      <>
        <div style={actionButtonStyles ? actionButtonStyles : {}} onClick={this.handleOpenDialog}>
          {actionButton}
        </div>
        <Dialog maxWidth={'md'} onClose={this.handleClose} aria-labelledby='simple-dialog-title' open={isOpened}>
          <Box pt={8} pb={6} pl={10} pr={10} style={windowStyles ? windowStyles : { minWidth: 500 }}>
            <Typography variant='h4' onClick={(e) => this.handleHeaderCloseButton(e)}>
              {header}
            </Typography>

            <Box mb={2} />
            {isLoading ? <LinearProgress /> : body}
            <Box mb={loadingMarginBottom} />
            <Grid container justifyContent='flex-end' spacing={5}>
              {cancelButtonText && (
                <Grid item>
                  <Button disabled={isLoading} onClick={this.handleCancel} variant='outlined'>
                    {cancelButtonText}
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  disabled={isLoading || disabledProceedButton}
                  style={proceedButtonStyles || {}}
                  variant='contained'
                  color='primary'
                  onClick={this.handleProceed}
                >
                  {proceedButtonText}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      </>
    )
  }
}
