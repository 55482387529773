import { TCampaignType } from '../../../../core/type'

export const CountriesListUtil = (campaignType: TCampaignType.native | TCampaignType.banner) => {
  const DEFAULT_ALLOWED_DISPLAY_LOCATIONS = ['de', 'at', 'ch']
  const DEFAULT_ALLOWED_NATIVE_LOCATIONS = ['de', 'at', 'ch']

  const NATIVE_CAMPAIGNS_LOCATIONS = process.env.REACT_APP_NATIVE_CAMPAIGN_ALLOWED_LOCATIONS
  const DISPLAY_CAMPAIGNS_LOCATIONS = process.env.REACT_APP_DISPLAY_CAMPAIGN_ALLOWED_LOCATIONS

  const COUNTRIES_LOCATIONS = {
    [TCampaignType.native]: NATIVE_CAMPAIGNS_LOCATIONS?.split(',') || DEFAULT_ALLOWED_NATIVE_LOCATIONS,
    [TCampaignType.banner]: DISPLAY_CAMPAIGNS_LOCATIONS?.split(',') || DEFAULT_ALLOWED_DISPLAY_LOCATIONS,
  }

  // console.info(
  //   `Provided native campaigns locations without REACT_APP ${process.env.NATIVE_CAMPAIGN_ALLOWED_LOCATIONS}`,
  //   `--- with REACT_APP ${process.env.REACT_APP_NATIVE_CAMPAIGN_ALLOWED_LOCATIONS}`
  // )
  // console.info('Provided native campaigns locations variable', NATIVE_CAMPAIGNS_LOCATIONS)
  // console.info(
  //   `Provided display campaigns locations without REACT_APP ${process.env.DISPLAY_CAMPAIGN_ALLOWED_LOCATIONS}`,
  //   `--- with REACT_APP ${process.env.REACT_APP_DISPLAY_CAMPAIGN_ALLOWED_LOCATIONS}`
  // )
  // console.info('Provided display campaigns locations variable', DISPLAY_CAMPAIGNS_LOCATIONS)
  //
  // console.info('Used locations')
  // console.table(COUNTRIES_LOCATIONS)

  return COUNTRIES_LOCATIONS[campaignType]
}
