import { createSlice } from '@reduxjs/toolkit'
import { ClientsApi } from 'api/clients/clients'
import type { LDApiReturnValue, RequestParamsI } from 'api/users/types'
import { Roles } from 'core/store/reducers/userReducer'
import type { AppDispatch, RootState } from 'core/store/store'

import type { ClientI } from './clientReducer'

const initialState = {
  clients: new Array<ClientI>(),
  isLoading: false,
  meta: {
    totalItems: 0,
  },
}

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    clientsLoading: (state) => {
      state.isLoading = true
    },
    clientsSuccess: (state, { payload }) => {
      const { data, meta } = payload as LDApiReturnValue<ClientI>

      state.isLoading = false
      state.clients = data
      state.meta = meta
    },
    clientsFailure: (state) => {
      state.isLoading = false
    },
  },
})

export default clientsSlice.reducer

const { clientsFailure, clientsLoading, clientsSuccess } = clientsSlice.actions

export const clientsMetaInfoSelector = (state: RootState) => state.clients.meta
export const isClientsLoadingSelector = (state: RootState) => state.clients.isLoading
export const clientsSelector = (state: RootState) => state.clients.clients

export const parseClientId = (clientIdLong: string): number => {
  const [clientIdString] = clientIdLong.match(/\d+$/) as RegExpMatchArray

  return parseInt(clientIdString)
}

export const clientsHashSelector = (state: RootState): Record<string, string> => {
  return state.clients.clients.reduce((acc, client) => {
    return {
      ...acc,
      [client['@id']]: client.name,
      [parseClientId(client['@id'])]: client.name,
    }
  }, {})
}

export function getClients() {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const role = getState().auth.user.role

    if (role === Roles.superadmin) {
      dispatch(getClientsBySelectedTenant())
    } else {
      dispatch(getAllClients())
    }
  }
}

export function getAllClients() {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(clientsLoading())

      const data = await ClientsApi.getAllClients()

      dispatch(clientsSuccess(data))
    } catch (error) {
      dispatch(clientsFailure())
    }
  }
}

export function getClientsBySelectedTenant() {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(clientsLoading())

      const selectedTenantId = getState().dropdownTenants.selectedTenant!.id
      const data = await ClientsApi.getByTenantId(selectedTenantId)

      dispatch(clientsSuccess(data))
    } catch (error) {
      dispatch(clientsFailure())
    }
  }
}

export const getTableClients = (queryParams?: RequestParamsI) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(clientsLoading())

      const ldResponse = await ClientsApi.getMany(queryParams!)

      dispatch(clientsSuccess(ldResponse))
    } catch (e) {
      dispatch(clientsFailure())

      // eslint-disable-next-line no-console
      console.log('Failed to query clients', e)
    }
  }
}
