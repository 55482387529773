import { GoogleMap } from '@react-google-maps/api'
import { AbsoluteLoader } from 'common/components/Loaders/AbsoluteLoader'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'

import { LocationTypes } from '../../../../core/type'
import { extendPolygon, makeCircleCameraPolygons } from '../helpers'
import type { ILocation, IMap } from '../index'
import { AddressLocation } from './AdressLocations'
import { useLocationsPolygonLoader } from './LocationPolygonsLoader'
import { PointerLocation } from './PointLocation'

const containerStyle: React.CSSProperties = {
  position: 'relative',
  width: '100%',
  height: 424,
}

const GERMANY_CENTER_COORDS = {
  lat: 51.1657,
  lng: 10.4515,
}

const getCameraPolygons = (locations: Array<ILocation>) => {
  return _.flattenDeep(
    locations.map(({ coordinates, lng, lat, type, radius }) => {
      if (type === LocationTypes.ADDRESS) {
        return coordinates
      } else {
        return makeCircleCameraPolygons({ lat: lat!, lng: lng! }, radius!)
      }
    })
  )
}

export const GoogleMapView: React.FC<IMap> = ({ locations, formik }) => {
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [zoom, setZoom] = useState<number | undefined>(map?.getZoom())

  const { geoData, isLoading } = useLocationsPolygonLoader({
    locations: formik?.values.locations || locations,
  })

  useEffect(() => {
    const zoomTracker = () => {
      setZoom(map?.getZoom())
    }

    map?.addListener('zoom_changed', zoomTracker)
  }, [map])

  useEffect(() => {
    extendPolygon()
  })

  // useEffect(() => {
  //   formik.setFieldValue('locations', geoData); // here we set all updated locations with coordinates
  // }, [geoData,]);

  const googleLocations = useMemo(() => {
    return geoData.map((location, index) => {
      if (location.type === 'address') {
        return <AddressLocation key={index} location={location!} map={map!} />
      } else {
        return <PointerLocation key={index} location={location!} />
      }
    })
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [geoData, zoom, map])

  const cameraPolygons = useMemo(() => getCameraPolygons(geoData), [geoData])

  const displayCenter = useMemo(() => cameraPolygons.length === 0, [cameraPolygons])

  useEffect(() => {
    if (map) {
      const allLocations = new google.maps.Polygon({
        paths: cameraPolygons,
      })
      // @ts-ignore
      map.fitBounds(allLocations.getBounds())
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [cameraPolygons])

  return (
    <GoogleMap
      onLoad={(googleMap) => setMap(googleMap)}
      mapContainerStyle={containerStyle}
      {...(displayCenter
        ? {
            center: {
              lat: GERMANY_CENTER_COORDS.lat,
              lng: GERMANY_CENTER_COORDS.lng,
            },
          }
        : {})}
      zoom={8}
    >
      {isLoading && <AbsoluteLoader />}
      {googleLocations}
    </GoogleMap>
  )
}
