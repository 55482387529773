import type { RequestParamsI, RequestParamsMap } from '../users/types'

export const formRequestUrl = (endpoint: string, { filters }: RequestParamsI, additionalParams = '') => {
  const requestUrl = `${endpoint}?`
  const serializedFilters = serializeRequestFilters(filters)
  let tailParams = additionalParams.startsWith('&') ? additionalParams : `&${additionalParams}`

  return requestUrl + serializedFilters + tailParams
}

export const serializeRequestFilters = (filters: RequestParamsMap) => {
  return Object.entries(filters)
    .map(([key, { formRequestParamAsArray, values }], index) => {
      const paramSign = index === 0 ? '' : '&'

      return values.map((value) => `${paramSign}${key}${formRequestParamAsArray ? '[]' : ''}=${value}`).join('&')
    })
    .join('')
}
