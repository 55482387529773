import ReactDOM, { render } from 'react-dom'

import reportWebVitals from './reportWebVitals'
import Root from './root'

render(<Root />, document.getElementById('root'))

// @ts-ignore
if (module.hot) {
  // this is necessary for HMR, the only thing we need for the whole app which is nice
  // @ts-ignore
  module.hot.accept('./root', () => {
    const NextRootContainer = require('./root').default
    // @ts-ignore
    ReactDOM.render(<NextRootContainer />, document.getElementById('app'))
  })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
