import { Box, Grid } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { FormikProvider, useFormik } from 'formik'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as NoteIcon } from '../../../../../assets/icons/note.svg'
import { FormikMaterialInput } from '../../../../../common/components/Inputs/FormikMaterialInput'
import { AbsoluteLoader } from '../../../../../common/components/Loaders/AbsoluteLoader'
import { ButtonWithDialog } from '../../../../../common/components/Modals/ButtonWithDialog'
import {
  commentSelector,
  getNote,
  isNoteExistSelector,
  isNoteLoadingSelector,
  noteOwnerUserNameSelector,
  updateNote,
} from '../../../../../core/store/reducers/noteReducer'
import { useStylesNote } from './noteStyles'
import { noteValidationSchema } from './schema'

type ButtonVariant = 'edit' | 'add'

interface NoteProps {
  campaignId: number
  isCopy?: boolean
}

const Note = ({ campaignId, isCopy }: NoteProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const classes = useStylesNote()

  const isNoteExist = useSelector(isNoteExistSelector)
  const isLoading = useSelector(isNoteLoadingSelector)
  const comment = useSelector(commentSelector)
  const noteUserName = useSelector(noteOwnerUserNameSelector)

  const initialValue = {
    comment: comment,
  }

  const formik = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    validationSchema: noteValidationSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch(updateNote(campaignId, values))

      resetForm()
    },
  })

  const { setFieldValue, errors, dirty } = formik

  const isProceedButtonDisabled = useMemo(() => {
    return !!Object.entries(errors).length
  }, [errors])

  useEffect(() => {
    dispatch(getNote(campaignId))
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [])

  const CustomButtonWithDialog = (variant: ButtonVariant) => {
    const actionButton =
      variant === 'edit' ? (
        <Box style={{ cursor: 'pointer' }} component={'span'} ml={1} onClick={() => setFieldValue('comment', comment)}>
          {t('campaigns.note.editNote')}
        </Box>
      ) : (
        <Box mt={1} className={classes.addBtnWrapper}>
          <Grid container alignItems={'flex-end'}>
            <Box className={classes.addIcon}>
              <NoteIcon className={classes.icon} />
            </Box>
            <Box component={'span'} className={classes.addBtnText}>
              {t('campaigns.note.addNote')}
            </Box>
          </Grid>
        </Box>
      )

    return (
      <FormikProvider value={formik}>
        <ButtonWithDialog
          shouldCloseBeConfirmed={dirty}
          disabledProceedButton={isProceedButtonDisabled}
          proceedHeaderCloseSelector={'close-button'}
          windowStyles={{
            minWidth: 672,
            minHeight: 220,
            padding: '24px 16px',
          }}
          header={
            <Grid item container className={classes.titleWrapper}>
              <Box className={classes.title}>
                {isNoteExist && t('campaigns.note.editNote')}
                {!isNoteExist && t('campaigns.note.addNote')}
              </Box>
              <CloseIcon id='close-button' className={classes.closeButton} />
            </Grid>
          }
          body={
            <FormikMaterialInput
              name={'comment'}
              label={t('campaigns.note.note')}
              value={formik.values.comment}
              maxLength={600}
              extendableToMultiline
              counter
              setTouchedOnChange
              helperText={''}
              minRows={1}
              maxRows={9}
            />
          }
          actionButton={actionButton}
          proceedButtonText={t('campaigns.note.save')}
          cancelButtonText={t('campaigns.note.cancel')}
          loadingMarginBottom={3}
          onProceed={() => {
            formik.submitForm()
          }}
        />
      </FormikProvider>
    )
  }

  const addNoteBtn = CustomButtonWithDialog('add')
  const editNoteBtn = CustomButtonWithDialog('edit')

  if (isCopy) {
    return null
  }

  if (isLoading) {
    return <AbsoluteLoader />
  }

  return (
    <>
      <Box ml={2}></Box>
      {isNoteExist && (
        <Grid item container className={classes.container}>
          <Box className={classes.contentWrapper}>
            <Box className={classes.nameBlock}>
              <Box className={classes.nameIcon}>
                <NoteIcon className={classes.icon} />
              </Box>
              <span>{`${noteUserName}:`}</span>
            </Box>
            <Box className={classes.text}>{comment}</Box>
          </Box>
          <Box className={classes.editButton}>{editNoteBtn}</Box>
        </Grid>
      )}
      {!isNoteExist && addNoteBtn}
    </>
  )
}

export default Note
