import { Checkbox, FormControlLabel, FormGroup, FormHelperText, Typography } from '@material-ui/core'
import type { FormikProps } from 'formik'
import type { FC } from 'react'
import React from 'react'

import type { AgeGroups } from '../../../core/store/reducers/campaign'

const AGE_VARIANT: Array<AgeGroups> = ['18-24', '25-34', '35-44', '45-54', '55-64', '65+']
export const AgeCheckboxes: FC<{ formik: FormikProps<any>; disabled?: boolean }> = ({ formik, disabled }) => {
  const { touched, handleChange, errors, setTouched } = formik
  const Checkboxes = AGE_VARIANT.map((variant) => {
    return (
      <FormControlLabel
        key={variant}
        control={
          <Checkbox
            disabled={disabled}
            name={`ageRanges.${variant}`}
            color='primary'
            onChange={(e) => {
              setTouched({ ...touched, ageRanges: true })
              handleChange(e)
            }}
            checked={formik.values.ageRanges[variant]}
          />
        }
        label={<Typography variant='inherit'>{variant}</Typography>}
      />
    )
  })

  return (
    <>
      <FormGroup row>{Checkboxes}</FormGroup>
      {errors.ageRanges && touched && <FormHelperText error>{errors.ageRanges}</FormHelperText>}
    </>
  )
}
