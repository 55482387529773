import axios from '../../core/axios'
import { getFileNameFromURL } from '../../pages/Campaigns/common/utils/AMPHTMLToImage'

export class BinaryLoader {
  public static async byUrl(url: string): Promise<File> {
    const { data } = await axios.get(url, {
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache',
      },
    })

    return new File([data], getFileNameFromURL(url)!, {
      type: data.type,
    })
  }
}
