import { createSelector, createSlice } from '@reduxjs/toolkit'
import { TenantAPI } from 'api/tenants/tenants'
import type { RequestParamsI } from 'api/users/types'
import { setSnackbar } from 'core/store/reducers/snackbar'
import type { AppDispatch, RootState } from 'core/store/store'
import translate from 'i18next'

import type { TenantI } from './dropdownTenants'

export interface ITenant {
  '@id': string
  id: number
  name: string
  createdAt: string
  updatedAt: string
}

interface ITenantState {
  isLoading: boolean
  tenants: Array<ITenant>
  totalItems: number
}

export const initialState: ITenantState = {
  isLoading: false,
  tenants: [],
  totalItems: 0,
}

const tenants = createSlice({
  name: 'tableTenants',
  initialState,
  reducers: {
    tableTenantsLoading: (state) => {
      state.isLoading = true
    },
    tableTenantsDeleted: (state, { payload }) => {
      state.tenants = state.tenants.filter(({ id }) => id !== payload)
      state.isLoading = false
    },
    tableTenantsSuccess: (state, { payload }) => {
      state.isLoading = false
      state.tenants = payload.tenants
      state.totalItems = payload.totalItems
    },
    tableTenantsError: (state) => {
      state.isLoading = false
    },
  },
})

export const { tableTenantsLoading, tableTenantsSuccess, tableTenantsDeleted, tableTenantsError } = tenants.actions

const isTenantsLoadingSelector = (state: RootState): boolean => state.tableTenants.isLoading

const tenantsSelector = (state: RootState): Array<ITenant> => state.tableTenants.tenants

const totalItemsSelector = (state: RootState): number => state.tableTenants.totalItems

export const tableTenantsSelector = createSelector(
  tenantsSelector,
  isTenantsLoadingSelector,
  totalItemsSelector,
  (allTenants, isLoading, totalItems) => ({
    tenants: allTenants,
    isLoading,
    totalItems,
  })
)

export default tenants.reducer

export function deleteTenant({ id, name }: TenantI) {
  return async (dispatch: AppDispatch) => {
    dispatch(tableTenantsLoading())

    try {
      await TenantAPI.delete(id)

      dispatch(tableTenantsDeleted(id))
      dispatch(setSnackbar(true, 'success', translate.t('tenants.deletedSuccessfully', { name })))
    } catch (error) {
      dispatch(tableTenantsError())
      dispatch(setSnackbar(true, 'error', translate.t('common.snackbars.errorOccurred')))
    }
  }
}

export function getTableTenants(params: RequestParamsI) {
  return async (dispatch: AppDispatch) => {
    dispatch(tableTenantsLoading())

    try {
      const result = await TenantAPI.getMany(params)

      dispatch(tableTenantsSuccess(result))
    } catch (error) {
      dispatch(tableTenantsError())
      dispatch(setSnackbar(true, 'error', 'Failed to get tenants'))
    }
  }
}
