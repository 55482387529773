import { createSelector, createSlice } from '@reduxjs/toolkit'
import { TenantAPI } from 'api/tenants/tenants'
import type { AppDispatch, RootState } from 'core/store/store'

export enum Countries {
  DE = 'DE',
  BE = 'BE',
  LI = 'LI',
  LU = 'LU',
  AT = 'AT',
  CH = 'CH',
}

export interface AdServersI {
  googleAds: number
  seedingAlliance: number
}

export interface TenantI {
  '@id': string
  id: number
  domain: string
  name: string
  createdAt: string
  updatedAt: string
  googleAdsCustomerId: string
  email: string
  countryCode: Countries
  zip: string
  city: string
  address: string
  phone: string
  adServers: AdServersI
}

interface ITenantState {
  isLoading: boolean
  tenants: Array<TenantI>
  selectedTenant: TenantI | null
  errors: string | null
}

export const initialState: ITenantState = {
  isLoading: false,
  tenants: [],
  selectedTenant: null,
  errors: null,
}

const dropdownTenants = createSlice({
  name: 'dropdownTenants',
  initialState,
  reducers: {
    tenantSelected: (state, { payload }) => {
      state.selectedTenant = payload
    },
    tenantsLoading: (state) => {
      state.isLoading = true
      state.errors = null
    },
    tenantsSuccess: (state, { payload }) => {
      state.isLoading = false
      state.errors = null
      state.tenants = payload
      state.selectedTenant = payload[0] || null
    },
    tenantsError: (state, { payload }) => {
      state.isLoading = false
      state.errors = payload
    },
  },
})

export const { tenantsLoading, tenantsSuccess, tenantsError, tenantSelected } = dropdownTenants.actions

export const isTenantsLoadingSelector = (state: RootState): boolean => state.dropdownTenants.isLoading

export const tenantsSelector = (state: RootState): Array<TenantI> => state.dropdownTenants.tenants

export const selectedTenantSelector = (state: RootState): TenantI | null => state.dropdownTenants.selectedTenant

export const tenantsIdsNamesHashSelector = createSelector(tenantsSelector, (tenants) => {
  return tenants.reduce((cumulative, { id, name }) => {
    return { ...cumulative, [id]: name }
  }, {})
})
export const tenantsPathNamesSelector = createSelector(tenantsSelector, (tenants) => {
  return tenants.reduce((cumulative, { '@id': innerId, name }) => {
    return { ...cumulative, [innerId]: name }
  }, {})
})

export default dropdownTenants.reducer

export function setSelectedTenant(tenantId: number) {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const tenants = tenantsSelector(getState())
    const selectedTenant = tenants.find(({ id }) => id === tenantId)

    dispatch(tenantSelected(selectedTenant))
  }
}

export function getTenants() {
  return async (dispatch: AppDispatch) => {
    dispatch(tenantsLoading())

    try {
      const { data } = await TenantAPI.getAll()

      dispatch(tenantsSuccess(data))
    } catch (error) {
      dispatch(tenantsError(error))
    }
  }
}
