import { loggedUserRoleSelector } from 'core/store/reducers/auth'
import type { Roles } from 'core/store/reducers/userReducer'
import type { FC } from 'react'
import { useSelector } from 'react-redux'

import { FullscreenLoader } from '../Loaders/FullscreenLoader'
import NotFound from '../NotFound'

//
type RolesVariant = {
  [role in Roles]?: JSX.Element | null
}

export const useRole: FC<RolesVariant> = (props) => {
  const role = useSelector(loggedUserRoleSelector)
  if (!role) {
    return <FullscreenLoader from={'useRole'} />
  }

  if (props[role] === null) {
    return null
  }

  if (props[role] === undefined) {
    return <NotFound />
  }

  return <>{props[role]}</>
}
