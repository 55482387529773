import Axios from '../../../core/axios'
import type { EditStrategy } from '../../../core/store/reducers/strategyReducer'
import { TBiddingStrategy } from '../../../core/type'
import { toEuroCents } from '../tenant/TenantStrategiesService'

export class CampaignStrategyAPI {
  static async put(campaignId: number | string, strategy: EditStrategy) {
    try {
      return Axios.put(`/campaigns/${campaignId}/bid-strategy`, strategyDeserializer(strategy))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while patching campaign strategy')

      return Promise.reject(error)
    }
  }
}

const strategyDeserializer = (strategy: EditStrategy) => {
  const { bidStrategy, bidValue } = strategy

  if (bidStrategy === TBiddingStrategy.MAX_CLICKS) {
    return { bidStrategy, bidValue: 0 }
  }
  const bidValueInCents = toEuroCents(+bidValue)

  return { bidStrategy, bidValue: bidValueInCents }
}
