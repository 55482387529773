import type { ILocation } from 'common/components/Maps'
import Axios from 'core/axios'
import { historyPushWithLocale } from 'core/history'
import { Routes } from 'core/router/routes'
import { parseClientId } from 'core/store/reducers/clientsReducer'
import type {
  Campaign,
  CampaignResponse,
  CampaignsResponse,
  EnhancedCampaign,
  ITargetParams,
  StatusDetails,
} from 'core/type'
import type { CampaignStatus, TransitionStatuses } from 'core/type'
import { saveAs } from 'file-saver'
import { cloneDeep } from 'lodash'

import { getCurrentLocale } from '../../core/router/hooks/useLocales'
import { fromEuroCents } from '../bidStrategies/tenant/TenantStrategiesService'
import { LocationAPI } from '../locations/locations'
import type { RequestParamsI } from '../users/types'
import { formRequestUrl } from '../utils/requestsUtils'

const campaignDeserializer = (campaign: Partial<EnhancedCampaign>) => {
  const { endDate: endDateRaw, startDate: startDateRaw, totalBudget, name, client, type } = campaign

  const totalBudgetInCents = +(totalBudget! * 100).toFixed(0)
  const startDate = startDateRaw ? new Date(startDateRaw) : startDateRaw
  const endDate = endDateRaw ? new Date(endDateRaw) : endDateRaw

  const result = {
    type,
    name,
    client,
    totalBudget: totalBudgetInCents,
    endDate,
  }

  if (startDate) {
    return {
      ...result,
      startDate,
    }
  } else {
    return result
  }
}

const campaignStatusSerializer = (data: StatusDetails): StatusDetails => {
  return {
    ...data,
    failureMessage: data.failureMessage?.replaceAll('://', ''),
  }
}

const campaignSerializer = (campaign: any) => {
  const {
    endDate: rawEndDate,
    startDate: startDateRaw,
    client: clientRaw,
    totalBudget: totalBudgetRaw,
    type,
    bidStrategy,
  } = campaign

  const client = parseClientId(clientRaw)
  const startDate = new Date(startDateRaw)
  const endDate = rawEndDate ? new Date(rawEndDate) : null
  const totalBudget = fromEuroCents(totalBudgetRaw)

  if (bidStrategy) {
    bidStrategy.bidValue = fromEuroCents(bidStrategy.bidValue)
  }

  const serializedLocations = locationsSerializer(campaign.locations)
  const serializedTarget = targetSerializer(campaign)

  return {
    ...campaign,
    ...serializedTarget,
    locations: serializedLocations,
    client,
    startDate,
    endDate,
    totalBudget,
    type,
  }
}

const locationsSerializer = (locations: Array<any> = []) => {
  return locations.map((location) => {
    return {
      address: null,
      enteredAddress: null,
      ...location,
      lat: location.latitude,
      lng: location.longitude,
    }
  })
}

const ageRangeDeserializer = (ages: string) => {
  switch (ages) {
    case '18-24':
      return {
        startAge: 18,
        endAge: 24,
      }
    case '25-34':
      return {
        startAge: 25,
        endAge: 34,
      }
    case '35-44':
      return {
        startAge: 35,
        endAge: 44,
      }
    case '45-54':
      return {
        startAge: 45,
        endAge: 54,
      }
    case '55-64':
      return {
        startAge: 55,
        endAge: 64,
      }
    case '65+':
      return {
        startAge: 65,
        endAge: 100,
      }
  }
}

const targetDeserializer = (targets: ITargetParams) => {
  const { genders, devices, ageRanges } = targets

  if (ageRanges) {
    const agesRangeToServer = Object.entries(ageRanges)
      .filter(([, value]) => value)
      .map(([age]) => {
        return ageRangeDeserializer(age)
      })

    return {
      genders,
      devices,
      ageRanges: agesRangeToServer,
    }
  } else {
    return {
      genders,
      devices,
    }
  }
}

export const targetSerializer = (campaign: any) => {
  const { genders, ages } = campaign

  const ageRanges = {
    '18-24': false,
    '25-34': false,
    '35-44': false,
    '45-54': false,
    '55-64': false,
    '65+': false,
  }

  ages.forEach((age: { startAge: string; endAge: string }) => {
    if (+age.startAge > 64) {
      ageRanges['65+'] = true

      return
    }
    const currentAge = `${age.startAge}-${age.endAge}` as keyof typeof ageRanges

    ageRanges[currentAge] = true
  })

  return {
    genders,
    ageRanges,
  }
}

class CampaignService {
  campaignDetails = async (id: string): Promise<CampaignResponse> => {
    try {
      const [{ data: campaign }, statusDetails] = await Promise.all([
        Axios.get(
          `campaigns/${id}?groups[]=age&groups[]=ads&groups[]=devices&groups[]=genders&groups[]=locations&groups[]=bidStrategy`,
          {
            headers: { Accept: 'application/json' },
          }
        ),
        CampaignAPI.getStatus(id),
      ])

      const campaignData: EnhancedCampaign = {
        ...campaign,
        statusDetails,
      }

      return campaignSerializer(campaignData) as any
    } catch (error) {
      return Promise.reject(error)
    }
  }

  delete = async (id: string | number) => {
    try {
      await Axios.delete(`campaigns/${id}`)

      historyPushWithLocale(Routes.CAMPAIGNS)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while deleting :(')

      return Promise.reject(error)
    }
  }

  create = async (
    campaign: Pick<EnhancedCampaign, 'client' | 'name' | 'startDate' | 'endDate' | 'totalBudget'>
  ): Promise<
    Pick<
      EnhancedCampaign,
      | 'client'
      | 'name'
      | 'startDate'
      | 'endDate'
      | 'createdAt'
      | 'id'
      | 'status'
      | 'tenant'
      | 'updatedAt'
      | 'totalBudget'
      | 'type'
    >
  > => {
    try {
      const { data } = await Axios.post('campaigns', campaignDeserializer(campaign), {
        headers: { Accept: 'application/json' },
      })

      return campaignSerializer(data)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while creating campaign')

      return Promise.reject(error)
    }
  }

  update = async (campaign: Partial<EnhancedCampaign>, id: number | string): Promise<Campaign> => {
    try {
      const { data } = await Axios.put(`campaigns/${id}`, campaignDeserializer(campaign), {
        headers: { Accept: 'application/json' },
      })

      return campaignSerializer(data)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while creating :(')

      return Promise.reject(error)
    }
  }

  updateTarget = async (targets: ITargetParams, id: number | string): Promise<ITargetParams> => {
    try {
      const { data } = await Axios.put(`campaigns/${id}/target`, targetDeserializer(targets), {
        headers: { Accept: 'application/json' },
      })

      return data
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while updating target')

      return Promise.reject(error)
    }
  }

  list = async (params: RequestParamsI): Promise<CampaignsResponse> => {
    try {
      let requestParams: RequestParamsI = cloneDeep(params)

      if (requestParams.filters['exists[packageCampaign]']) {
        // crunch to make filter by subscription work as expected
        const values = requestParams.filters['exists[packageCampaign]'].values

        if (values.includes('true') && values.includes('false')) {
          delete requestParams.filters['exists[packageCampaign]']
        } else {
          requestParams.filters['exists[packageCampaign]'].formRequestParamAsArray = false
        }
      }

      const { data } = await Axios.get(
        formRequestUrl('campaigns', requestParams, '&groups[]=client&groups[]=packageCampaign'),
        {
          headers: { Accept: 'application/ld+json' },
        }
      )

      return {
        campaigns: data['hydra:member'],
        totalItems: data['hydra:totalItems'],
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while getting campaign list')

      return Promise.reject(error)
    }
  }

  getClientByCampaignId = async (id: string) => {
    try {
      const { data } = await Axios.get(`campaigns/${id}/client`, {
        headers: { Accept: 'application/json' },
      })

      return data
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while getting client')

      return Promise.reject(error)
    }
  }

  generateReport = async (campaignId: number | string, campaignName: string) => {
    try {
      const currentTimeZone = new Date().getTimezoneOffset() / 60

      const { data } = await Axios.get(
        `campaigns/${campaignId}/report?lang=${getCurrentLocale()}&timezone=${currentTimeZone}`,
        {
          headers: { Accept: 'application/json' },
        }
      )

      const { data: report } = await Axios.get(window.location.origin + data.url, {
        responseType: 'blob',
      })

      await saveAs(new Blob([report], { type: 'application/octetstream' }), `${campaignName}.pdf`)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while getting report', error)

      return Promise.reject(error)
    }
  }

  getStatus = async (id: number | string): Promise<StatusDetails> => {
    const { data } = await Axios.get(`campaigns/${id}/status`, {
      headers: { Accept: 'application/json' },
    })

    return campaignStatusSerializer(data)
  }

  setStatus = async (id: number, transition: TransitionStatuses): Promise<{ status: CampaignStatus }> => {
    try {
      const { data } = await Axios.put(
        `campaigns/${id}/status`,
        { transition },
        {
          headers: { Accept: 'application/json' },
        }
      )

      return data.status
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while getting url')

      return Promise.reject(error)
    }
  }

  getCampaignLocations = async (companyId: number): Promise<Array<ILocation>> => {
    try {
      const { data }: { data: { 'hydra:member': Array<ILocation> } } = await Axios.get(
        `campaigns/${companyId}/locations?pagination=false`
      )

      return Promise.all(
        data['hydra:member'].map((location) => {
          return LocationAPI.getLocationDetails(location)
        })
      )
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error while getting company locations  :(')

      return Promise.reject(error)
    }
  }
}

export const CampaignAPI = new CampaignService()
