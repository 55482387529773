import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { campaignSelector } from '../../../../core/store/reducers/campaign'

export const useCampaignTitle = () => {
  const campaign = useSelector(campaignSelector)

  return useEffect(() => {
    const oldTitle = document.title

    if (campaign.id) {
      document.title = campaign.id.toString()
    }

    return () => {
      document.title = oldTitle
    }
  }, [campaign.id])
}
