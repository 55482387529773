import { Box } from '@material-ui/core'
import type { FC } from 'react'

import type { ICounterProps } from './types'

export const InputCounter: FC<ICounterProps> = ({ helperText, currentLength, maxLength }) => {
  return (
    <Box component='span' sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <span>{helperText}</span>
      {currentLength >= 0 && maxLength > 0 && <span>{`${currentLength} / ${maxLength}`}</span>}
    </Box>
  )
}
