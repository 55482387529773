import type { lazy } from 'react'
import { Suspense } from 'react'

import { FullscreenLoader } from '../../../common/components/Loaders/FullscreenLoader'

export const LazyLoading = (LazyImported: ReturnType<typeof lazy>) => {
  return (
    <div>
      <Suspense fallback={<FullscreenLoader from={'top level component loader'} />}>
        <LazyImported />
      </Suspense>
    </div>
  )
}
