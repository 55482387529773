import { createSlice } from '@reduxjs/toolkit'
import { TenantAPI } from 'api/tenants/tenants'
import { setSnackbar } from 'core/store/reducers/snackbar'
import type { AppDispatch, RootState } from 'core/store/store'
import type { AdServerTypes } from 'core/type'
import translate from 'i18next'

export interface EditableRow {
  isEdited: boolean
}

export interface TargetImpressions {
  id: number
  server: AdServerTypes
  budget: number
  targetImpressions: number | null
  createdAt: string
  updatedAt: string
}

interface targetImpressionsInitialState {
  targetImpressions: Array<TargetImpressions & EditableRow>
  isLoading: boolean
}

export const initialState: targetImpressionsInitialState = {
  targetImpressions: [] as Array<TargetImpressions & EditableRow>,
  isLoading: false,
}

const targetImpressions = createSlice({
  name: 'targetImpressions',
  initialState,
  reducers: {
    targetImpressionsLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    targetImpressionsError: (state) => {
      state.isLoading = false
    },
    targetImpressionsSuccess: (state, { payload }) => {
      state.targetImpressions = payload
      state.isLoading = false
    },
    addTargetImpressions: (state, { payload }) => {
      state.targetImpressions = [...state.targetImpressions, payload]
    },
    deleteTargetImpressions: (state, { payload }) => {
      state.targetImpressions = state.targetImpressions.filter(({ id }) => id !== payload.id)
    },
    updateTargetImpressions: (state, { payload }) => {
      state.targetImpressions = state.targetImpressions.map((targetImpression) => {
        if (targetImpression.id === payload.id) {
          targetImpression.targetImpressions = payload.targetImpressions
          targetImpression.budget = payload.budget
          targetImpression.isEdited = false
        }

        return targetImpression
      })
    },
    editTargetImpressions: (state, { payload }) => {
      state.targetImpressions = state.targetImpressions.map((targetImpression) => {
        if (targetImpression.id === payload.id) {
          targetImpression.isEdited = payload.isEdited
        }

        return targetImpression
      })
    },
    resetTargetImpressions: () => initialState,
  },
})

export const targetImpressionsIsLoadingSelector = (state: RootState) => state.budgets.isLoading

export const getBudgetsByServer = (state: RootState) => (requiredServer: AdServerTypes) => {
  const entities = state.targetImpressions.targetImpressions.filter(({ server }) => requiredServer === server)

  const budget = entities.map(({ budget: impressionBudget }) => impressionBudget)

  return {
    entities,
    budget,
  }
}

export const {
  addTargetImpressions,
  targetImpressionsError,
  targetImpressionsLoading,
  targetImpressionsSuccess,
  deleteTargetImpressions,
  editTargetImpressions,
  resetTargetImpressions,
  updateTargetImpressions,
} = targetImpressions.actions

export default targetImpressions.reducer

export const getTenantTargetImpressions = (tenantId: number | string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(targetImpressionsLoading(true))

    try {
      const fetchedTargetImpressions = await TenantAPI.getTenantTargetImpressions(tenantId)
      dispatch(targetImpressionsSuccess(fetchedTargetImpressions))
    } catch (error) {
      dispatch(targetImpressionsError())
      dispatch(setSnackbar(true, 'error', translate.t('tenant.budgets.failedToLoad')))
    }
  }
}
