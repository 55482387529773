import translate from 'i18next'

import { getCurrentLocale } from '../core/router/hooks/useLocales'
import { de } from './de'
import { en } from './en'

;(async () => {
  await translate.init({
    resources: {
      de,
      en,
    },
    lng: getCurrentLocale(),
    fallbackLng: 'de',
    interpolation: { escapeValue: false },
  })
})()
