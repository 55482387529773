import { MapsApi } from 'api/maps/maps'
import { useEffect, useRef, useState } from 'react'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

import type { ILocation, PointCoordinates } from '../index'

interface CoordinatesCache {
  coordinates: Array<Array<PointCoordinates>>
  lat: number
  lng: number
  displayAs: ILocation['displayAs']
}

export const useLocationsPolygonLoader = ({ locations }: { locations: Array<ILocation> }) => {
  const { current: coordinatesCache } = useRef(new Map<string, CoordinatesCache>())
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [geoData, setGeoData] = useState<Array<ILocation>>([])

  const geoDataDependencyKey = locations.reduce((culumative, { enteredAddress, address, type, radius }) => {
    return culumative + enteredAddress + address + radius + type
  }, '')

  useEffect(() => {
    // prettier-ignore
    (async () => {
      setIsLoading(true)

      const updatedLocations: Array<ILocation> = await Promise.all(
        locations.map(async (location) => {
          const { address, enteredAddress } = location
          const addressToRequest = address || enteredAddress!

          let locationCoordinates: CoordinatesCache

          if (!addressToRequest) {
            return {
              ...location,
              coordinates: [[{ lat: location.lat!, lng: location.lng! }]],
            }
          }

          if (coordinatesCache.has(addressToRequest)) {
            locationCoordinates = coordinatesCache.get(addressToRequest)!
          } else {
            const { coordinates, displayAs } = await MapsApi.getLocationPolygons(addressToRequest)
            const [geocode] = await geocodeByAddress(addressToRequest)
            const { lat, lng } = await getLatLng(geocode)

            const fallbackAreaCoordinates = coordinates?.length === 0 ? [[{ lat, lng }]] : coordinates!

            locationCoordinates = {
              coordinates: fallbackAreaCoordinates,
              lat,
              lng,
              displayAs,
            }

            coordinatesCache.set(addressToRequest, locationCoordinates)
          }

          return {
            ...location,
            ...locationCoordinates,
          }
        })
      )
      setGeoData(updatedLocations)
      setIsLoading(false)
    })()
  }, [geoDataDependencyKey, locations, coordinatesCache])

  return {
    isLoading,
    geoData,
  }
}
