import { Marker, Polygon } from '@react-google-maps/api'
import _ from 'lodash'
import type { FC } from 'react'

import type { ILocation } from '..'
import { getPolygonCenter, getPostalIndexMeasures } from '../helpers'
import customIcon from '../icons/circle.png'

const options = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1,
}

const MINIMUM_POSTAL_CODE_ICON_WIDTH = 4000

type Props = {
  location: ILocation
  map: google.maps.Map
}

export const AddressLocation: FC<Props> = ({ location, map }): JSX.Element => {
  if (location.displayAs === 'circle') {
    const polygonArea = google.maps.geometry.spherical.computeArea(_.flattenDeep(location.coordinates))
    const circleDiameter = Math.sqrt(polygonArea / Math.PI)
    const iconSize = circleDiameter > MINIMUM_POSTAL_CODE_ICON_WIDTH ? circleDiameter : MINIMUM_POSTAL_CODE_ICON_WIDTH

    const fakeCircle = new google.maps.Circle({
      map,
      radius: iconSize,
      center: getPolygonCenter(location.coordinates!),
      fillOpacity: 0,
      strokeOpacity: 0,
    })

    const { size, anchor } = getPostalIndexMeasures(map.getZoom()!, iconSize)

    const icon: google.maps.Icon = {
      url: customIcon,
      size: new google.maps.Size(size, size),
      scaledSize: new google.maps.Size(size, size),
      anchor: new google.maps.Point(anchor, anchor),
    }

    return (
      <>
        <Marker
          key={location.address}
          options={{
            icon,
          }}
          position={{
            lat: fakeCircle.getCenter()!.lat()!,
            lng: fakeCircle.getCenter()!.lng()!,
          }}
        />
        <Marker
          key={'marker' + location.address}
          position={{
            lat: fakeCircle.getCenter()!.lat()!,
            lng: fakeCircle.getCenter()!.lng()!,
          }}
        />
      </>
    )
  } else {
    return (
      <>
        <Polygon paths={location.coordinates} options={options} />
        <Marker
          key={location.address}
          position={{
            lat: location.lat!,
            lng: location.lng!,
          }}
        />
      </>
    )
  }
}
