import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { getCurrentLocalePath } from '../../../core/router/hooks/useLocales'

export class LinkUnstyled extends Component<{ to: string; external?: boolean }> {
  render() {
    const { to, children } = this.props

    return (
      <Link to={getCurrentLocalePath() + to} data-testid={to} style={{ textDecoration: 'inherit', color: 'inherit' }}>
        {children}
      </Link>
    )
  }
}
