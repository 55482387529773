import { FullscreenLoader } from 'common/components/Loaders/FullscreenLoader'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { INITIAL_LOCATION_PATH } from '../../constants'
import { historyPushWithLocale } from '../../history'
import { LocalStorage } from '../../localStorage'
import { themeLoadedSelector } from '../../store/reducers/appearance'
import { Routes, isAuthRoute, isPrivateRoute } from '../routes'

const TokenChecker: FC = ({ children }) => {
  const themeLoaded = useSelector(themeLoadedSelector)
  const [isLoading, setLoading] = useState<boolean>(true)
  const { pathname, search } = window.location
  const hasToken = !!localStorage.getItem('token')

  // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  useEffect(() => {
    if (!isPrivateRoute(pathname) && !isAuthRoute(pathname)) {
      setLoading(false)
    } else if (!hasToken && !isPrivateRoute(pathname)) {
      setLoading(false)
    } else if (!hasToken && isPrivateRoute(pathname)) {
      setLoading(false)

      LocalStorage.set(INITIAL_LOCATION_PATH, pathname + search)
      historyPushWithLocale(Routes.LOGIN)
    } else if (hasToken && !isPrivateRoute(pathname)) {
      setLoading(false)

      historyPushWithLocale(Routes.ROOT)
    } else {
      setLoading(false)
    }
  })

  if (!themeLoaded) {
    return <FullscreenLoader from={'Token checker. Theme loading'} />
  }

  return <>{isLoading ? <FullscreenLoader from={'Token checker'} /> : children}</>
}

export default TokenChecker
