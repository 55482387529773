import { ReactComponent as TabErrorWarning } from 'assets/icons/tab_warning.svg'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import type { StatusDetails } from '../../../../../core/type'
import { failedStatusDetails } from '../CampaignFailDetails'

export const useTabLabel = (statusDetails: StatusDetails, tabValue: string) => {
  const [showTabIcon, setShowTabIcon] = useState<boolean>(true)

  const errorDetails = useMemo(() => failedStatusDetails(statusDetails), [statusDetails])

  const iconByTab = useCallback(
    (tabName: string, label: string, isSelectedFieldError: boolean = true) => {
      if (showTabIcon && tabName === errorDetails.tabName && isSelectedFieldError) {
        return {
          label: (
            <>
              <TabErrorWarning />
              {label}
            </>
          ),
        }
      } else {
        return { label: label }
      }
    },
    [errorDetails, showTabIcon]
  )

  useEffect(() => {
    setShowTabIcon(errorDetails.tabName !== tabValue)
  }, [tabValue, errorDetails])

  return iconByTab
}
