import { Box, Grid } from '@material-ui/core'
import NoImage from 'assets/icons/no_image_banner_desktop.png'
import React, { useEffect } from 'react'

import { Desktop } from '../../devices/Desktop'
import { BlockSimulation } from '../../devices/markupSimulation/block'

const CreateDesktop = ({ imageUrl }: { imageUrl?: string }) => {
  useEffect(() => {
    const screen = document.querySelector('.screen')
    const screenFirstDescendant = document.querySelector('.screen>div')

    if (screen) {
      screen.setAttribute('style', 'overflow: hidden;background: white;')
    }

    if (screenFirstDescendant) {
      screenFirstDescendant.setAttribute('style', 'position: absolute; width: 100%;')
    }
  })

  return (
    <Desktop>
      <Box p={12}>
        <Grid container spacing={10} direction={'row'}>
          <Grid item xs={8}>
            <BlockSimulation height={20} />
            <Box mt={5}>
              <BlockSimulation height={20} width={50} />
            </Box>
            <Box mt={5}>
              <BlockSimulation height={20} />
            </Box>
            <Box mt={5}>
              <BlockSimulation height={20} width={80} />
            </Box>
            <Box mt={5}>
              <BlockSimulation height={500} />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <img alt={'native preview'} src={imageUrl || NoImage} />
            <Box mt={4} mb={4}>
              <BlockSimulation height={250} />
            </Box>
            <Box mt={4} mb={4}>
              <BlockSimulation height={250} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Desktop>
  )
}

export default CreateDesktop
