import translator from 'i18next'

import { getDaysDiff } from '../../new/steps/campaign/schema'

export const totalDays = (isTimeCheckboxActive: boolean, startDate: string, endDate: string) => {
  const hoursCount = calcTimeInHours(startDate, endDate)
  const daysCount = getDaysDiff(startDate, endDate)
  if (isTimeCheckboxActive && hoursCount > 0) {
    // eslint-disable-next-line max-len
    return `${getDaysDiff(startDate, endDate)} ${translator.t('campaigns.fields.days')} (${hoursCount} ${translator.t(
      'campaigns.fields.hours'
    )})`
  }

  if (daysCount && daysCount > 0) {
    return `${getDaysDiff(startDate, endDate)} ${translator.t('campaigns.fields.days')}`
  }

  return ''
}

export const calcTimeInHours = (startDate: string, endDate: string) => {
  return Math.round((new Date(endDate).getTime() - new Date(startDate).getTime()) / 1000 / 60 / 60)
}
