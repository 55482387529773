import React, { Component } from 'react'

import { FullscreenLoader } from '../Loaders/FullscreenLoader'

interface WithLoaderParams<T = any> {
  executable: () => Promise<T>
  onError?: (...args: any[]) => void
  onSuccess?: (...args: any[]) => void
  allowAccessAnyway?: boolean
  from: string
}

export const WithLoader = ({ onError, onSuccess, executable, allowAccessAnyway, from }: WithLoaderParams) =>
  // eslint-disable-next-line @typescript-eslint/ban-types
  class extends Component<{}, { isLoading: boolean }> {
    state = { isLoading: true }

    componentDidMount() {
      executable()
        .then((result) => onSuccess && onSuccess(result))
        .then(() => this.setState({ isLoading: false }))
        .catch((e) => onError && onError(e))
        .finally(() => {
          if (allowAccessAnyway) {
            this.state.isLoading && this.setState({ isLoading: false })
          }
        })
    }

    render() {
      return <>{this.state.isLoading ? <FullscreenLoader from={from} /> : this.props.children}</>
    }
  }
