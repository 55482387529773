import { Circle, Marker } from '@react-google-maps/api'
import type { FC } from 'react'
import { useEffect, useState } from 'react'

import type { ILocation } from '../index'

const options = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1,
}

type Props = {
  location: ILocation
}

export const PointerLocation: FC<Props> = ({ location }): JSX.Element => {
  const [radius, setRadius] = useState<number>()

  useEffect(() => {
    setRadius(location.radius)
  }, [location])

  return (
    <>
      <Marker
        position={{
          lat: location.lat!,
          lng: location.lng!,
        }}
      />
      <Circle
        radius={radius! * 1000}
        center={{
          lat: location.lat!,
          lng: location.lng!,
        }}
        options={options}
      />
    </>
  )
}
