import { AdServersAPI } from 'api/adServers/adServers'
import { adServersSuccess } from 'core/store/reducers/adServers'
import store from 'core/store/store'

import { WithLoader } from '../../common/components/Preloaders/WithLoader'

const AdServersLoader = WithLoader({
  executable: async () => AdServersAPI.getAdServers(),
  onError: () => null,
  onSuccess: (adServers) => store.dispatch(adServersSuccess(adServers)),
  from: 'AdServersLoader',
})

export default AdServersLoader
