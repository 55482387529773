import _ from 'lodash'

import type { AddedImage, IImage } from '../../common/components/Images/types'
import { IMAGE_GROUP } from '../../common/components/Images/types'
import { filterByGroup } from '../../pages/Campaigns/new/steps/advertising/native/schema'
import { ImageAPI } from './images'

export class ImagesWorker {
  private static async proceedPairs(pairs: Array<[AddedImage | IImage | undefined, IImage | undefined]>, adId: number) {
    for (const pair of pairs) {
      const [toCreate, toDelete] = pair
      if (!toDelete && toCreate) {
        if (toCreate.id === null) {
          await this.makeNewImage(toCreate as AddedImage, adId)
        }
      }

      if (!toCreate && toDelete) {
        await ImageAPI.delete(toDelete.id)
      }

      if (toCreate && toDelete) {
        if (toCreate.id === null) {
          await this.makeNewImage(toCreate as AddedImage, adId)
        }

        await ImageAPI.delete(toDelete.id)
      }

      if (!toCreate && !toDelete) {
        return
      }
    }
  }

  private static makeNewImage({ type, group, file }: AddedImage, adId: number) {
    const formData = new FormData()

    type && formData.append('type', type)
    group && formData.append('group', group)
    formData.append('file', file)
    formData.append('ad', `${adId}`)

    return ImageAPI.create(formData)
  }

  public static async update(newImages: Array<AddedImage | IImage>, oldImages: Array<IImage>, adId: number) {
    const imagesToDelete = oldImages.filter((oldImage) => !newImages.some((newImage) => newImage.id === oldImage.id))
    const imagesToCreate = _.difference(newImages, oldImages)

    const pairwiseLogos = _.zip(
      filterByGroup(imagesToCreate, IMAGE_GROUP.LOGO),
      filterByGroup(imagesToDelete, IMAGE_GROUP.LOGO)
    )

    const pairwiseImages = _.zip(
      filterByGroup(imagesToCreate, IMAGE_GROUP.IMAGE),
      filterByGroup(imagesToDelete, IMAGE_GROUP.IMAGE)
    )

    return this.proceedPairs(
      [...pairwiseImages, ...pairwiseLogos] as Array<[AddedImage | IImage | undefined, IImage | undefined]>,
      adId
    )
  }
}
