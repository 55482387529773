import { createSlice } from '@reduxjs/toolkit'

import { NoteServiceAPI } from '../../../pages/Campaigns/common/Components/Note/noteService'
import type { AppDispatch, RootState } from '../store'
import type { UserI } from './userReducer'

export interface INote {
  comment: string
  id: number
  user: UserI
}
type NoteComment = {
  comment: string
}

interface NoteState {
  notes: Array<INote>
  isLoading: boolean
}

const initialState: NoteState = {
  notes: [],
  isLoading: false,
}

const noteReducer = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    noteLoading: (state) => {
      state.isLoading = true
    },
    update: (state, { payload }) => {
      state.notes = payload
      state.isLoading = false
    },
  },
})

export default noteReducer.reducer

const { noteLoading, update } = noteReducer.actions

export const isNoteLoadingSelector = (state: RootState) => state.notes.isLoading
export const isNoteExistSelector = (state: RootState) => !!state.notes.notes[0]
export const commentSelector = (state: RootState) => state.notes.notes[0]?.comment
export const noteOwnerUserNameSelector = (state: RootState) => state.notes.notes[0]?.user?.name

export const getNote = (campaignId: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(noteLoading())

      const result = await NoteServiceAPI.get(campaignId)

      dispatch(update(result))
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Failed to update Note', e)
    }
  }
}

export const updateNote = (campaignId: number, note: NoteComment) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(noteLoading())

      const result = await NoteServiceAPI.update(campaignId, note)

      dispatch(update(result))
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Failed to update Note', e)
    }
  }
}
