import type { FC } from 'react'
import React from 'react'

import type { Device } from '../banner/images'
import Desktop from '../banner/images/Desktop'
import Mobile from '../banner/images/Mobile'

interface Props {
  deviceType: Device
  selectedImageUrl: string
}

const HandleImagesDeviceType: FC<Props> = ({ deviceType, selectedImageUrl }) => {
  if (deviceType === 'desktop') {
    return <Desktop imageUrl={selectedImageUrl} />
  } else {
    return <Mobile imageUrl={selectedImageUrl} />
  }
}

export default HandleImagesDeviceType
