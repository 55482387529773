import _ from 'lodash'

import type { PointCoordinates } from '../index'

export function getPolygonCenter(coords: Array<PointCoordinates> | Array<Array<PointCoordinates>>) {
  const flatCoords = _.flattenDeep(coords)
  const lats = flatCoords.map((point) => point.lat).sort((l1, l2) => l1 - l2)
  const lngs = flatCoords.map((point) => point.lng).sort((l1, l2) => l1 - l2)
  const lat = lats[0] + (lats[lats.length - 1] - lats[0]) / 2
  const lng = lngs[0] + (lngs[lngs.length - 1] - lngs[0]) / 2

  return {
    lat,
    lng,
  }
}

export function getCirclePath(circle: google.maps.Circle) {
  const numPts = 26
  const path = []
  for (let i = 0; i < numPts; i++) {
    path.push(google.maps.geometry.spherical.computeOffset(circle.getCenter()!, circle.getRadius(), (i * 360) / numPts))
  }

  return path
}

export const makeCircleCameraPolygons = ({ lng, lat }: PointCoordinates, radius: number) => {
  const circle = new google.maps.Circle({ center: { lat: lat!, lng: lng! }, radius: radius! * 1000 })
  const polygons = getCirclePath(circle)!

  return polygons.map(({ lng: polygonLng, lat: polygonLat }) => ({ lat: polygonLat(), lng: polygonLng() }))
}

export const getPostalIndexMeasures = (zoom: number, postalCodeIconWIdth: number) => {
  const postalCodeIconWidth = postalCodeIconWIdth // meters;

  const GOOGLE_ZERO_ZOOM_METERS_PER_PIXEL_RATIO = {
    // taken from https://groups.google.com/g/google-maps-js-api-v3/c/hDRO4oHVSeM
    zoom: 0,
    meters: 156543.03392,
  }

  const zoomDiff = GOOGLE_ZERO_ZOOM_METERS_PER_PIXEL_RATIO.zoom - zoom
  const metersPerPixelRatio = Math.pow(2, zoomDiff) * GOOGLE_ZERO_ZOOM_METERS_PER_PIXEL_RATIO.meters

  const size = ((postalCodeIconWidth / metersPerPixelRatio) * Math.PI) / 2

  return {
    size,
    anchor: size / 2,
  }
}

export const extendPolygon = () => {
  // @ts-ignore
  google.maps.Polygon.prototype.getBounds = function () {
    const bounds = new google.maps.LatLngBounds()
    ;(this.getPaths() || []) /* hot reload fix*/
      .forEach((p) => {
        p.forEach((element: any) => bounds.extend(element))
      })

    return bounds
  }
}
