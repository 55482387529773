import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

export const useChipStyles = makeStyles<Theme>((theme: Theme) => ({
  MuiChip: {
    borderRadius: '4px',
    padding: '5px 8px',
    height: 'unset',
    backgroundColor: theme.palette.success[100],

    '& .MuiChip-label': {
      padding: 0,
      color: theme.palette.success[200],
      fontSize: '12px',
      letterSpacing: '0.4px',
      fontWeight: 400,
      lineHeight: '14px',
    },
  },
}))
