import { AdAttachmentType, Genders, TBiddingStrategy, TCampaignType } from 'core/type'
import { isBefore, set } from 'date-fns'
import translator from 'i18next'
import * as Yup from 'yup'

import type { AddedImage, IImage } from '../../../../common/components/Images/types'
import { IMAGE_GROUP, IMAGE_TYPE } from '../../../../common/components/Images/types'
import { CampaignStatus } from '../../../../core/type'
import {
  MAX_ALLOWED_IMAGES,
  MAX_ALLOWED_LOGOS,
  MIN_ALLOWED_IMAGES,
  filterByGroup,
  filterByType,
} from '../../new/steps/advertising/native/schema'
import { getDailyBudget } from '../../new/steps/campaign/schema'

export const MAXIMUM_LOCATIONS_QUANTITY = 35
const MAXIMUM_DAILY_BUDGET = 4000

export const campaignValidationSchema = () => {
  const locationsValidator = Yup.array()
    .min(1)
    .of(
      Yup.object().shape({
        address: Yup.string().nullable(),
        type: Yup.string().nullable(),
      })
    )

  return Yup.object()
    .shape({
      ad: Yup.object().shape({
        type: Yup.string()
          .required(translator.t('common.validation.required', { field: 'Type' }))
          .oneOf([TCampaignType.native, TCampaignType.banner]),
      }),
      images: Yup.array(),
      files: Yup.array(),
      adAttachmentType: Yup.string().oneOf([AdAttachmentType.IMAGE, AdAttachmentType.AMPHTML]).required(),
      gender: Yup.string().oneOf([Genders.men, Genders.women, Genders.all]).default(Genders.all),
      name: Yup.string()
        .trim()
        .when('type', {
          is: TCampaignType.native,
          then: Yup.string().max(150, translator.t('common.validation.lengthMax', { length: 150 })),
        })
        .max(255, translator.t('common.validation.lengthMax', { length: 255 }))
        .required(translator.t('common.validation.required', { field: translator.t('campaigns.fields.campaign') })),
      startDate: Yup.string()
        .nullable()
        .required(translator.t('common.validation.required', { field: translator.t('campaigns.fields.startDate') })),
      dailyBudget: Yup.number().optional().nullable(),
      endDate: Yup.string()
        .nullable()
        .required(translator.t('common.validation.required', { field: translator.t('campaigns.fields.endDate') })),
      totalBudget: Yup.number().positive(),
      client: Yup.string().required(
        translator.t('common.validation.required', { field: translator.t('clients.client') })
      ),
      devices: Yup.object({
        desktop: Yup.boolean(),
        mobile: Yup.boolean(),
      }).test('one of device', 'devices', (obj) => {
        if (obj.desktop || obj.mobile) {
          return true
        }

        return new Yup.ValidationError(
          translator.t('campaigns.validation.atLeastOneDeviceShouldBeChosen'),
          null,
          'devices'
        )
      }),
      bidStrategy: Yup.object({
        bidStrategy: Yup.string().when('type', {
          is: TCampaignType.banner,
          then: Yup.string()
            .oneOf(
              [TBiddingStrategy.CPM, TBiddingStrategy.CPC, TBiddingStrategy.MAX_CLICKS],
              translator.t('campaigns.optimizationBiddingValidationMessage')
            )
            .nullable()
            .test('bidStrategy', translator.t('campaigns.optimizationBiddingValidationMessage'), function (value) {
              return [TBiddingStrategy.CPM, TBiddingStrategy.CPC, TBiddingStrategy.MAX_CLICKS].includes(
                value as TBiddingStrategy
              )
            }),
          otherwise: Yup.string().nullable().notRequired(),
        }),
        bidValue: Yup.mixed().when('bidStrategy', (bidStrategy) => {
          const translate = (fieldName: string) => {
            return translator.t('common.validation.positive', {
              field: translator.t(`campaigns.optimizationBidding.${fieldName}`),
            })
          }

          if (bidStrategy === TBiddingStrategy.CPC) {
            return Yup.number().min(0, translate('labelMaxCpc'))
          } else if (bidStrategy === TBiddingStrategy.CPM) {
            return Yup.number().min(0, translate('labelMaxCpm'))
          } else {
            return Yup.mixed().optional()
          }
        }),
      }),
      locations: Yup.array().optional(),
      isSubscriptionActive: Yup.boolean().required().default(false),
      previousCampaign: Yup.object().when('isSubscriptionActive', {
        is: true,
        then: Yup.object()
          .shape({
            name: Yup.string(),
          })
          .required(
            translator.t('common.validation.required', {
              field: translator.t('campaigns.fields.campaign'),
            })
          )
          .nullable(),
        otherwise: Yup.object().optional().nullable(),
      }),
    })
    .test(
      'startDate',
      translator.t('campaigns.validation.startDateCannotBeEarlierThanTomorrow'),
      async ({ status, startDate }) => {
        if (status === CampaignStatus.draft) {
          if (isBefore(new Date(startDate as any), new Date())) {
            return new Yup.ValidationError(
              translator.t('campaigns.validation.startDateCannotBeEarlierThanTomorrow'),
              null,
              'startDate'
            )
          }
        }

        return true
      }
    )
    .test(
      'endDate',
      translator.t('campaigns.validation.endDateCannotBeEarlierThanTomorrow'),
      async ({ status, endDate, startDate }) => {
        if (status === CampaignStatus.draft) {
          const newEndDate = set(new Date(endDate as any), { hours: 0, minutes: 0, seconds: 0 })
          if (isBefore(new Date(newEndDate), new Date())) {
            return new Yup.ValidationError(
              translator.t('campaigns.validation.endDateCannotBeEarlierThanTomorrow'),
              null,
              'endDate'
            )
          }
          if (isBefore(new Date(newEndDate), new Date(startDate as any))) {
            return new Yup.ValidationError(
              translator.t('campaigns.validation.endDateCannotBeEarlierThanStartDate'),
              null,
              'endDate'
            )
          }
        }

        return true
      }
    )
    .test(
      'totalBudget',
      translator.t('campaigns.validation.totalBudgetRange'),
      async function ({ totalBudget, startDate, endDate }) {
        const dailyBudgetForFixed = getDailyBudget(startDate, endDate, totalBudget as number)

        if (dailyBudgetForFixed) {
          if (dailyBudgetForFixed > MAXIMUM_DAILY_BUDGET || dailyBudgetForFixed < 4) {
            return new Yup.ValidationError(translator.t('campaigns.validation.totalBudgetRange'), null, 'totalBudget')
          }

          return true
        }

        return new Yup.ValidationError(translator.t('campaigns.validation.totalBudgetRange'), null, 'totalBudget')
      }
    )
    .test(
      translator.t('campaigns.validation.locationsCannotAddMore', {
        number: MAXIMUM_LOCATIONS_QUANTITY,
      }),
      'locations',
      async ({ locations }) => {
        if (
          locations &&
          // @ts-ignore
          locations.length > MAXIMUM_LOCATIONS_QUANTITY
        ) {
          return new Yup.ValidationError(
            translator.t('campaigns.validation.locationsMaximumReached', {
              number: MAXIMUM_LOCATIONS_QUANTITY,
            }),
            null,
            'locations'
          )
        }

        return true
      }
    )
    .test(translator.t('campaigns.validation.locationsWereNotProvided'), 'locations', async ({ locations }) => {
      if (!(await locationsValidator.isValid(locations))) {
        return new Yup.ValidationError(translator.t('campaigns.validation.locationsListShouldBeSet'), null, 'locations')
      } else {
        return true
      }
    })
    .test(
      translator.t('campaigns.validation.maximumImagesError', {
        allowed: MAX_ALLOWED_LOGOS,
      }),
      'images',
      async ({ images, type }) => {
        if (
          type === TCampaignType.native &&
          filterByGroup(images as Array<IImage | AddedImage>, IMAGE_GROUP.LOGO).length > MAX_ALLOWED_LOGOS
        ) {
          return new Yup.ValidationError(
            translator.t('campaigns.validation.maximumImagesError', {
              allowed: MAX_ALLOWED_LOGOS,
            }),
            null,
            'images'
          )
        }

        return true
      }
    )
    .test(
      translator.t('campaigns.validation.maximumImagesError', {
        allowed: MAX_ALLOWED_LOGOS,
      }),
      'images',
      async ({ images, type }) => {
        if (
          type === TCampaignType.native &&
          filterByGroup(images as Array<IImage | AddedImage>, IMAGE_GROUP.IMAGE).length > MAX_ALLOWED_IMAGES
        ) {
          return new Yup.ValidationError(
            translator.t('campaigns.validation.maximumImagesError', {
              allowed: MAX_ALLOWED_LOGOS,
            }),
            null,
            'images'
          )
        }

        return true
      }
    )
    .test(
      translator.t('campaigns.validation.atLeastOneShouldBeSet', {
        resolution: '1:1',
      }),
      'images',
      async ({ images, type }) => {
        if (
          type === TCampaignType.native &&
          filterByType(filterByGroup(images as Array<IImage | AddedImage>, IMAGE_GROUP.IMAGE), IMAGE_TYPE.SQUARE)
            .length < MIN_ALLOWED_IMAGES
        ) {
          return new Yup.ValidationError(
            translator.t('campaigns.validation.atLeastOneShouldBeSet', {
              resolution: '1:1',
            }),
            null,
            'images'
          )
        }

        return true
      }
    )
    .test(
      translator.t('campaigns.validation.atLeastOneShouldBeSet', {
        resolution: '1.91:1',
      }),
      'images',
      async ({ images, type }) => {
        if (
          type === TCampaignType.native &&
          filterByType(filterByGroup(images as Array<IImage | AddedImage>, IMAGE_GROUP.IMAGE), IMAGE_TYPE.HORIZONTAL)
            .length < MIN_ALLOWED_IMAGES
        ) {
          return new Yup.ValidationError(
            translator.t('campaigns.validation.atLeastOneShouldBeSet', {
              resolution: '1.91:1',
            }),
            null,
            'images'
          )
        }

        return true
      }
    )
    .test(
      translator.t('campaigns.validation.atLeastOneImageShouldBeSet'),
      'images',
      async ({ images, type, adAttachmentType }) => {
        if (type === TCampaignType.banner && adAttachmentType === AdAttachmentType.IMAGE && images!.length === 0) {
          return new Yup.ValidationError(
            translator.t('campaigns.validation.atLeastOneImageShouldBeSet'),
            null,
            'images'
          )
        }

        return true
      }
    )
    .test(
      translator.t('campaigns.validation.amphtmlShouldBeAdded'),
      'files',
      async ({ files, type, adAttachmentType }) => {
        if (type === TCampaignType.banner && adAttachmentType === AdAttachmentType.AMPHTML && files!.length === 0) {
          return new Yup.ValidationError(translator.t('campaigns.validation.amphtmlShouldBeAdded'), null, 'files')
        }

        return true
      }
    )
}
