import type { EditStrategy } from '../../../core/store/reducers/strategyReducer'
import { CampaignStrategyAPI } from './CampaignStrategiesAPI'

export class CampaignStrategiesService {
  update = async (campaignId: number | string, strategy: EditStrategy) => {
    return CampaignStrategyAPI.put(campaignId, strategy)
  }
}

export const CampaignStrategyServiceAPI = new CampaignStrategiesService()
