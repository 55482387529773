import { createSlice } from '@reduxjs/toolkit'
import { AdAPI } from 'api/ads/ads'
import { AMPHTMLWorker } from 'api/campaigns/AMPHTMLWorker'
import { CampaignAPI } from 'api/campaigns/campaign'
import { ImagesWorker } from 'api/images/ImagesWorker'
import { ImageAPI } from 'api/images/images'
import { LocationAPI } from 'api/locations/locations'
import type { ILocation } from 'common/components/Maps'
import type { AppDispatch, RootState } from 'core/store/store'
import store from 'core/store/store'
import type { Campaign, EnhancedCampaign } from 'core/type'
import { AdAttachmentType, Devices, Genders, TCampaignType } from 'core/type'
import { CampaignStatus, Statuses } from 'core/type'
import type { FormikValues } from 'formik'

import { CampaignStrategyServiceAPI } from '../../../api/bidStrategies/campaign/CampaignStrategiesService'
import { SubscriptionService } from '../../../api/subscription/subscription'
import type { ValidationStep } from '../../../pages/Campaigns/common/Components/Stepper/linear'
import { historyPushWithLocale } from '../../history'
import { Roles } from './userReducer'

export const initialCampaignData: EnhancedCampaign = {
  ad: {
    id: null,
    name: 'Ad1',
    type: null,
    url: 'https://',
    subtype: AdAttachmentType.IMAGE,
    headline: '',
    description: '',
    businessName: '',
  },
  statusDetails: {
    status: '' as any,
  },
  id: '' as any,
  name: '',
  startDate: null,
  endDate: null,
  status: '',
  client: '',
  tenant: '',
  updatedAt: '',
  createdAt: '',
  bidStrategy: {
    bidStrategy: null,
    bidValue: '',
  },
  ageRanges: {
    '18-24': true,
    '25-34': true,
    '35-44': true,
    '45-54': true,
    '55-64': true,
    '65+': true,
  },
  devices: { mobile: true, desktop: false },
  gender: Genders.all,
  adAttachmentType: AdAttachmentType.IMAGE,
  totalBudget: '' as any,
  images: [],
  files: [],
  locations: new Array<ILocation>(),
  redirectedToWizard: false,
  type: null,
  isSubscriptionActive: false,
  packages: [],
  previousCampaign: {
    id: null,
  } as any as Campaign,
}

export type AgeGroups = '18-24' | '25-34' | '35-44' | '45-54' | '55-64' | '65+'

interface ICampaignState {
  status: Statuses
  campaign: EnhancedCampaign
  errors: string | null
  imageUploadingError: string | null
}

export const initialState: ICampaignState = {
  status: Statuses.NONE,
  campaign: initialCampaignData,
  errors: null,
  imageUploadingError: null,
}

const campaign = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    campaignIsLoading: (state) => {
      state.status = Statuses.LOADING
      state.errors = null
    },
    campaignSuccess: (state, { payload }) => {
      state.status = Statuses.SUCCESS
      state.errors = null
      state.campaign = { ...state.campaign, ...payload }
    },
    campaignUpdateStatus: (state, { payload }) => {
      state.status = Statuses.SUCCESS
      state.errors = null
      state.imageUploadingError = null
      state.campaign = {
        ...state.campaign,
        status: payload,
      }
    },
    campaignError: (state, { payload }) => {
      state.status = Statuses.ERROR
      state.errors = payload
      state.imageUploadingError = null
    },
    campaignImageUploadingError: (state, { payload }) => {
      state.imageUploadingError = payload
    },
    campaignReset: () => initialState,
    locations: (state, { payload }) => {
      state.campaign.locations = payload
    },
  },
})

export const { campaignIsLoading, campaignSuccess, campaignError, campaignReset, campaignUpdateStatus } =
  campaign.actions

export const campaignStatusSelector = (state: RootState): Statuses => state.campaign.status

export const campaignSelector = (state: RootState): EnhancedCampaign => state.campaign.campaign

export default campaign.reducer

export const getCampaign = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(campaignIsLoading())

    try {
      const { locations, genders, ageRanges, devices, ads, client, ...campaignDetails } =
        await CampaignAPI.campaignDetails(id)
      const images = ads[0] ? await ImageAPI.list(ads[0].id!) : []
      const files = ads[0] ? await AdAPI.getFiles(ads[0].id!) : []
      const adAttachmentType = ads[0] ? ads[0].subtype : AdAttachmentType.IMAGE

      const { previousCampaign, packages, isSubscriptionActive } = await SubscriptionService.getInfo(
        client,
        campaignDetails.id
      )

      const locationsWithCoordinates = await Promise.all(
        locations.map((location) => LocationAPI.getLocationDetails(location))
      )

      const campaignData = {
        ...campaignDetails,
        client,
        packages,
        previousCampaign,
        isSubscriptionActive,
        adAttachmentType,
        files,
        locations: locationsWithCoordinates,
        ageRanges,
        ...(ads[0] ? { ad: ads[0] } : {}),
        devices: {
          mobile: !!devices.find((device) => device.type === Devices.mobile),
          desktop: !!devices.find((device) => device.type === Devices.desktop),
        },
        gender:
          genders.length > 1
            ? Genders.all
            : genders.find(({ gender }) => gender === Genders.men)?.gender ||
              genders.find(({ gender }) => gender === Genders.women)?.gender,
        images: images.sort((a, b) => a.id - b.id), // for comparison reasons
      }

      if (!images.length && !files.length) {
        dispatch(campaignSuccess({ ...campaignData, redirectedToWizard: true }))
      } else {
        dispatch(campaignSuccess(campaignData))
      }
    } catch (error) {
      dispatch(campaignError(error))
    }
  }
}

export const updateCampaign = async (currentCampaign: EnhancedCampaign, userRole: Roles) => {
  const { locations, images: newImages, files: newFiles, adAttachmentType, ad, bidStrategy, id } = currentCampaign

  const oldImages = await ImageAPI.list(currentCampaign.ad.id as number)
  const oldFiles = await AdAPI.getFiles(ad.id!)
  await ImagesWorker.update(newImages, oldImages, ad.id!)
  const updatedFiles = await AMPHTMLWorker.update(newFiles, oldFiles, ad.id!)
  await AdAPI.updateSubtype(adAttachmentType, ad.id!)

  if (currentCampaign.type === TCampaignType.banner && userRole === Roles.superadmin) {
    await CampaignStrategyServiceAPI.update(id, bidStrategy)
  }

  await SubscriptionService.update(currentCampaign, currentCampaign.id)

  const campaignToUpdate: Record<string, any> = {
    status: currentCampaign.status,
    totalBudget: currentCampaign.totalBudget,
    type: currentCampaign.type,
    name: currentCampaign.name,
    startDate: currentCampaign.startDate,
    endDate: currentCampaign.endDate,
  }

  if (currentCampaign.status === CampaignStatus.active) {
    delete campaignToUpdate?.startDate
  }

  await Promise.all([
    CampaignAPI.update(campaignToUpdate, currentCampaign.id),

    CampaignAPI.updateTarget(
      {
        devices: Object.keys(currentCampaign.devices)
          // @ts-ignore
          .filter((device) => currentCampaign.devices[device])
          .join(','),
      },
      currentCampaign.id
    ),
    LocationAPI.recreateAddresses(currentCampaign.id, locations),
  ])

  store.dispatch(campaignSuccess({ files: updatedFiles }))
}

export const copyCampaign = async (
  formik: FormikValues,
  targetingForm: ValidationStep,
  dynamicAdForm: ValidationStep,
  userRole: Roles
) => {
  const { locations, images, devices, files, adAttachmentType, bidStrategy } = formik.values

  const newCampaign = await CampaignAPI.create(formik.values)

  await SubscriptionService.create(
    {
      ...formik.values,
      previousCampaign: {
        id: formik.values.id,
      },
    },
    newCampaign.id
  )

  await LocationAPI.recreateAddresses(newCampaign.id, locations)

  if (newCampaign.type === TCampaignType.banner && userRole === Roles.superadmin) {
    await CampaignStrategyServiceAPI.update(newCampaign.id, bidStrategy)
  }

  await CampaignAPI.updateTarget(
    {
      devices: Object.keys(devices)
        .filter((device) => devices[device as keyof typeof Devices])
        .join(','),
    },
    newCampaign.id
  )
  targetingForm.formik?.setFieldValue('id', newCampaign.id, false) // saving values to new campaign
  await targetingForm.submit()

  const Ad = await AdAPI.createFromValues(formik.values, newCampaign.id, dynamicAdForm.formik!.values)
  const copiedImages = await ImageAPI.copyList(images)
  await ImagesWorker.update(copiedImages, [], Ad.id!)
  const copiedFiles = await AMPHTMLWorker.copyList(files)
  await AMPHTMLWorker.update(copiedFiles, [], Ad.id!)
  await AdAPI.updateSubtype(adAttachmentType, Ad.id!)

  await dynamicAdForm.resetForm()
  await formik.resetForm({ values: formik.values })

  historyPushWithLocale(`/campaigns/view/${newCampaign.id}`)
}
