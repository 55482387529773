import axios from 'axios'
import type MockAdapter from 'axios-mock-adapter'
import browserHistory, { historyPushWithLocale } from 'core/history'
import { LocalStorage } from 'core/localStorage'

import { INITIAL_LOCATION_PATH } from './constants'
import { getLocaleHeaderValue } from './router/hooks/useLocales'
import { Routes, isPrivateRoute } from './router/routes'

const getToken = () => LocalStorage.get('token')
const isTokenSet = () => !!getToken()
const makeTokenHeader = () => `Bearer ${getToken()}`

const getApiUrl = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'development': {
      return 'https://dev.cockpit.yeew.de/api/v1/'
    }

    case 'production': {
      return '/api/v1/'
    }

    default: {
      return 'http://mock/' // Done to highlight unmocked urls
    }
  }
}

const Axios = axios.create({
  baseURL: getApiUrl(),
})

const HOSTINGS_NAMES = ['amazonaws.com']

Axios.interceptors.request.use(
  (request) => {
    for (const hosting of HOSTINGS_NAMES) {
      if (request.url?.includes(hosting)) {
        return request
      }
    }

    if (request.headers['Authorization']) {
      // case for sing-in
      return request
    }
    if (!isTokenSet && !browserHistory.location.pathname.includes(Routes.LOGIN)) {
      historyPushWithLocale(Routes.LOGIN)

      return Promise.reject(null)
    }

    request.headers.common['Authorization'] = makeTokenHeader()
    request.headers.common['Content-Type'] = 'application/json'
    request.headers.common['Accept-Language'] = getLocaleHeaderValue()

    return request
  },
  (error) => {
    return Promise.reject(error)
  }
)

/* interceptor for popups/ notifications */
// Axios.interceptors.response.use(response => response, error => {
//   if(error.response) {
//     const { url, }  = error.request;
//
//     // 400 and 500 errors
//     store.dispatch(showModal({
//       code: error.response.status,
//     }));
//   } else {
//     store.dispatch(showModal({
//       code: error.message,
//     }));
//
//     // no response here ^ no internet or blocked
//     // debugger;
//   }
//
//   return Promise.reject(error);
// });

Axios.interceptors.response.use(
  (response) => {
    // if(process.env.REACT_APP_ENVIRONMENT === 'test') {
    //   console.error('####################################################');
    //   console.error('DATA', JSON.stringify(response, null, 2));
    //   console.error('####################################################');
    // }

    return response
  },
  (error) => {
    if (process.env.REACT_APP_ENVIRONMENT === 'test') {
      // console.error('####################################################');
      // console.error('DATA', JSON.stringify(error, null, 2));
      // console.error('####################################################');
    }

    if (error.response.status === 401 || error.response.status === 403 || !isTokenSet) {
      if (!localStorage.getItem(INITIAL_LOCATION_PATH) && isPrivateRoute(window.location.pathname)) {
        LocalStorage.set(INITIAL_LOCATION_PATH, window.location.pathname)
      }

      LocalStorage.remove('token')
      historyPushWithLocale(Routes.LOGIN)

      return Promise.reject(error)
    }

    return Promise.reject(error)
  }
)

let AxiosMock: MockAdapter

if (process.env.NODE_ENV === 'test') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const MockAdapter = require('axios-mock-adapter')
  AxiosMock = new MockAdapter(Axios)
}
export { AxiosMock }

export default Axios
