const LOCALE_CONFIG: Record<
  string,
  {
    fullLocaleName: string
    shortDateFormat: string
    fullDateFormat: string
    fullSlashDateFormat: string
    slashedDateFormat: string
  }
> = {
  de: {
    fullLocaleName: 'de-DE',
    slashedDateFormat: 'dd/MM/yyyy',
    fullSlashDateFormat: 'dd/MM/yyyy HH:mm',
    shortDateFormat: 'dd-MM-yyyy',
    fullDateFormat: 'dd–MM–yyyy HH:mm',
  },
  en: {
    fullLocaleName: 'en-US',
    slashedDateFormat: 'MM/dd/yyyy',
    fullSlashDateFormat: 'dd/MM/yyyy HH:mm',
    shortDateFormat: 'MM-dd-yyyy',
    fullDateFormat: 'MM–dd–yyyy HH:mm',
  },
}

const defaultLocale = process.env.REACT_APP_DEFAULT_LOCALE || 'de'
const defaultLocalePath = `/${defaultLocale}`
const LOCALE_REGEX = /\/([de|en]{2})\//

export const getCurrentLocaleConfig = () => LOCALE_CONFIG[getCurrentLocale() as keyof typeof LOCALE_CONFIG]

export const getLocaleHeaderValue = () => {
  if (isWithLocale(window.location.pathname)) {
    return LOCALE_CONFIG[getCurrentLocale() as keyof typeof LOCALE_CONFIG].fullLocaleName
  } else {
    return LOCALE_CONFIG[defaultLocale as keyof typeof LOCALE_CONFIG].fullLocaleName
  }
}

export const switchLocale = (newLocale: string) => {
  if (newLocale !== getCurrentLocale()) {
    window.location.pathname = window.location.pathname.replace(getCurrentLocale()!, newLocale)
  }
}

export const isWithLocale = (path: string) => !!path.match(LOCALE_REGEX)?.[1]

export const getCurrentLocale = () => window.location.pathname.match(LOCALE_REGEX)?.[1]

export const getCurrentLocalePath = () => {
  const locale = getCurrentLocale()

  return locale ? '/' + locale : ''
}

export const useLocales = () => {
  const pathname = window.location.pathname

  /* redirects from old ui links */
  if (pathname.startsWith('/new')) {
    window.location.pathname = pathname.replace('/new', '')

    return
  }

  if (!getCurrentLocalePath()) {
    window.location.pathname = defaultLocalePath + pathname
  }
}
