import type { Path } from 'history'
import { createBrowserHistory } from 'history'

import { getCurrentLocalePath, isWithLocale } from './router/hooks/useLocales'

const browserHistory = createBrowserHistory()
export const historyPushWithLocale = (location: Path): void => {
  const nextPath = isWithLocale(location) ? location : getCurrentLocalePath() + location

  browserHistory.push(nextPath)
}

export default browserHistory
