import { differenceInDays, isBefore, isValid, set } from 'date-fns'
import translator from 'i18next'
import * as Yup from 'yup'

import { TCampaignType } from '../../../../../core/type'

const MAXIMUM_DAILY_BUDGET = 4000

export const getDaysDiff = (startDate: unknown, endDate: unknown) => {
  if (
    (typeof startDate === 'string' || startDate instanceof Date) &&
    (typeof endDate === 'string' || endDate instanceof Date)
  ) {
    return differenceInDays(new Date(new Date(endDate)), new Date(new Date(startDate))) + 1
  }
}

export const getDailyBudget = (startDate: unknown, endDate: unknown, totalBudget: number): number => {
  const daysDiff = getDaysDiff(startDate, endDate)

  if (totalBudget && daysDiff) {
    return totalBudget / daysDiff
  }

  return 0
}

export default Yup.object()
  .shape({
    name: Yup.string()
      .trim()
      .when('type', {
        is: TCampaignType.native,
        then: Yup.string().max(150, translator.t('common.validation.lengthMax', { length: 150 })),
      })
      .max(255, translator.t('common.validation.lengthMax', { length: 255 }))
      .required(
        translator.t('common.validation.required', {
          field: translator.t('campaigns.fields.campaign'),
        })
      ),
    endDate: Yup.string()
      .nullable()
      .required(
        translator.t('common.validation.required', {
          field: translator.t('campaigns.fields.endDate'),
        })
      ),
    startDate: Yup.string()
      .required(
        translator.t('common.validation.required', {
          field: translator.t('campaigns.fields.startDate'),
        })
      )
      .nullable()
      .test('startDate', translator.t('campaigns.validation.useShortDateFormat'), function (value) {
        return isValid(new Date(value!))
      }),
    dailyBudget: Yup.number().optional().nullable(),
    totalBudget: Yup.number().positive(),
    client: Yup.string().required(
      translator.t('common.validation.required', {
        field: translator.t('clients.client'),
      })
    ),
    isSubscriptionActive: Yup.boolean().required().default(false),
    previousCampaign: Yup.object().when('isSubscriptionActive', {
      is: true,
      then: Yup.object()
        .shape({
          name: Yup.string(),
        })
        .required(
          translator.t('common.validation.required', {
            field: translator.t('campaigns.fields.campaign'),
          })
        )
        .nullable(),
      otherwise: Yup.object().optional().nullable(),
    }),
  })
  .test(
    'startDate',
    translator.t('campaigns.validation.startDateCannotBeEarlierThanTomorrow'),
    async ({ startDate }) => {
      if (isBefore(new Date(startDate as any), new Date())) {
        return new Yup.ValidationError(
          translator.t('campaigns.validation.startDateCannotBeEarlierThanTomorrow'),
          null,
          'startDate'
        )
      }

      return true
    }
  )
  .test(
    'endDate',
    translator.t('campaigns.validation.endDateCannotBeEarlierThanTomorrow'),
    async ({ endDate, startDate }) => {
      const newEndDate = set(new Date(endDate as any), { hours: 0, minutes: 0, seconds: 0 })
      if (isBefore(new Date(newEndDate), new Date())) {
        return new Yup.ValidationError(
          translator.t('campaigns.validation.endDateCannotBeEarlierThanTomorrow'),
          null,
          'endDate'
        )
      }

      if (isBefore(new Date(newEndDate), new Date(startDate as any))) {
        return new Yup.ValidationError(
          translator.t('campaigns.validation.endDateCannotBeEarlierThanStartDate'),
          null,
          'endDate'
        )
      }

      return true
    }
  )
  .test(
    'totalBudget',
    translator.t('campaigns.validation.totalBudgetRange'),
    async function ({ totalBudget, startDate, endDate }) {
      const dailyBudgetForFixed = getDailyBudget(startDate, endDate, totalBudget as number)

      if (dailyBudgetForFixed) {
        if (dailyBudgetForFixed > MAXIMUM_DAILY_BUDGET || dailyBudgetForFixed < 4) {
          return new Yup.ValidationError(translator.t('campaigns.validation.totalBudgetRange'), null, 'totalBudget')
        }

        return true
      }

      return new Yup.ValidationError(translator.t('campaigns.validation.totalBudgetRange'), null, 'totalBudget')
    }
  )
