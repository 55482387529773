import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { GoogleMapView } from 'common/components/Maps/Components/GoogleMap'
import type { EnhancedCampaign } from 'core/type'
import { useFormik } from 'formik'
import translator from 'i18next'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { LocationsSection } from '../../../../../common/components/Maps/Components/LocationsSection'
import { CategoryHeader } from '../../../../../common/components/Typography/CategoryHeader'
import type { CampaignFailDetails } from '../../common/CampaignFailDetails'
import { campaignValidationSchema } from '../../edit/schema'
import { AdServerErrorMessage } from '../common/AdServerErrorMessage'
import { TargetingView } from '../dynamic/Targeting'

const useStyles = makeStyles({
  MuiChip: {
    textOverflow: 'ellipsis',
    maxWidth: 400,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: 5,
    margin: '0px 5px 5px 5px',
  },
  MuiCard: {
    padding: 16,
    margin: '24px 0',
    borderRadius: '4px',
    border: '1px solid #E0E0E0',
    overflow: 'visible',
  },
  selectorConteiner: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  slider: {
    '& .MuiSlider-valueLabel': {
      left: 'calc(-50% - 8px)',
    },
    '& .MuiSlider-valueLabel > span': {
      width: 29,
      height: 24,
      transform: 'scale(1) translateY(60px)',
      borderRadius: 'unset',
    },
    '& .MuiSlider-valueLabel > span > span': {
      transform: 'none',
    },
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  disabledText: {
    color: 'rgb(0, 0, 0)',
  },
  item: {
    width: 588,
  },
})

interface Props {
  campaign: EnhancedCampaign
  errorDetails: CampaignFailDetails
}

export const CampaignManagementTargeting: React.FC<Props> = ({ campaign, errorDetails }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { locations } = campaign
  const isLocationError = useMemo(() => errorDetails.fieldNames?.includes('locations'), [errorDetails])

  const formik = useFormik({
    initialValues: campaign,
    enableReinitialize: true,
    validationSchema: campaignValidationSchema(),
    validateOnMount: false,
    onSubmit: () => {},
  })

  const TargetingForm = TargetingView(campaign.type)

  return (
    <>
      {campaign.locations && (
        <>
          <Grid container justifyContent={'space-between'} spacing={6}>
            <Grid item xs={6}>
              <Box mb={2}>
                <CategoryHeader value={t('campaigns.locationHeading')} />
              </Box>
              {isLocationError && (
                <Box mb={4}>
                  <AdServerErrorMessage
                    FAQHref={2}
                    message={translator.t(`campaigns.adServerErrorDescription.${errorDetails.failureMessage}`)}
                  />
                </Box>
              )}
              <LocationsSection formik={formik} disabled={true} />
            </Grid>
            <Grid item xs={6}>
              <GoogleMapView formik={formik} locations={locations} />
            </Grid>
          </Grid>
        </>
      )}
      <Box mt={8} mb={2}>
        <CategoryHeader value={t('campaigns.targetingHeading')} />
      </Box>
      <Grid container spacing={3} direction='column'>
        <Grid item xs={6}>
          <Typography variant='subtitle1'>{t('campaigns.devices')}</Typography>
          <FormGroup row>
            <FormControlLabel
              disabled
              control={<Checkbox checked={campaign.devices.desktop} color='primary' name='devices.desktop' />}
              label={
                <Typography className={classes.disabledText} variant='inherit'>
                  {t('campaigns.desktop')}
                </Typography>
              }
            />
            <FormControlLabel
              disabled
              control={<Checkbox checked={campaign.devices.mobile} color='primary' name='devices.mobile' />}
              label={
                <Typography className={classes.disabledText} variant='body1'>
                  {t('campaigns.mobile')}
                </Typography>
              }
            />
          </FormGroup>
        </Grid>
        {TargetingForm.Content}
      </Grid>
    </>
  )
}
