import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from 'core/store/store'

interface ErrorModalReducerInitialStateI {
  isShown: boolean
  code: number
}

const initialState: ErrorModalReducerInitialStateI = {
  isShown: false,
  code: 0,
}

export const errorHandlingModalSlice = createSlice({
  name: 'errorHandlingModal',
  initialState,
  reducers: {
    showModal: (state, { payload }) => {
      state.code = payload.code
      state.isShown = true
    },
    hideModal: () => initialState,
  },
})

export const errorHandlingModal = errorHandlingModalSlice.reducer

export const { showModal, hideModal } = errorHandlingModalSlice.actions

export const errorHandlingModalSelector = (state: RootState) => state.errorHandlingModal
