import { t } from 'i18next'
import React from 'react'
import { useSelector } from 'react-redux'

import { ReadonlyField } from '../../../../../common/components/Typography/ReadonlyField'
import { campaignSelector } from '../../../../../core/store/reducers/campaign'
import { TBiddingStrategy } from '../../../../../core/type'

export const BiddingReadOnlyFieldLabel = () => {
  const { bidStrategy } = useSelector(campaignSelector)

  const Content = []

  if (bidStrategy.bidStrategy === TBiddingStrategy.CPC) {
    Content.push(
      <ReadonlyField
        name={'optimization.bidStrategy'}
        label={t('tenants.budgets.optimization.cpcParameter')}
        body={bidStrategy.bidValue + ' €'}
      />
    )
  }
  if (bidStrategy.bidStrategy === TBiddingStrategy.CPM) {
    Content.push(
      <ReadonlyField
        name={'optimization.bidStrategy'}
        label={t('tenants.budgets.optimization.cpmParameter')}
        body={bidStrategy.bidValue + ' €'}
      />
    )
  }

  return Content
}
