class StorageWrapper {
  get = (key: string): string | null | undefined => {
    try {
      const serializedState = localStorage.getItem(key)
      if (!serializedState) {
        console.log(`Item "${key}" is absent in local storage`)

        return
      }

      return JSON.parse(serializedState)
    } catch (err) {
      console.log(err)

      console.log('cannot get the item')
    }
  }

  remove = (key: string): void => {
    try {
      localStorage.removeItem(key)
    } catch (err) {
      console.log('cannot remove the item')
    }
  }

  set = <T>(key: string, value: T): void => {
    try {
      const serializedState = JSON.stringify(value)
      localStorage.setItem(key, serializedState)
    } catch (err) {
      console.log("can't set the item")
    }
  }

  clear = () => {
    try {
      localStorage.clear()
    } catch (err) {
      console.log("can't set clear the storage")
    }
  }
}

export const LocalStorage = new StorageWrapper()
