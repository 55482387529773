import axios from 'core/axios'

import type { ThemeNames } from '../../core/palette'
import type { Appearance } from '../../core/store/reducers/appearance'
import type { LogoInfo } from '../tenantAppearance/types'
import type { ServerAppearance } from './types'

export const appearanceSerializer = ({ isCustomLogo, isPoweredBy, theme, baseColor }: Appearance) => {
  return {
    theme,
    poweredBy: isPoweredBy,
    customLogo: isCustomLogo,
    customPrimaryColor: baseColor?.replaceAll('#', '').toUpperCase(),
  }
}

export const appearanceDeserializer = ({
  poweredBy,
  customLogo,
  theme,
  customPrimaryColor,
}: ServerAppearance): Appearance => {
  const serverTheme = theme as ThemeNames

  return {
    theme: serverTheme,
    baseColor: customPrimaryColor
      ? customPrimaryColor.startsWith('#')
        ? customPrimaryColor.toUpperCase()
        : `#${customPrimaryColor}`
      : '',
    isPoweredBy: poweredBy,
    isCustomLogo: customLogo,
  }
}

export class AppearanceService {
  static getOwnAppearance = async (): Promise<Appearance> => {
    try {
      const { data } = await axios.get('/appearance', {
        headers: { Accept: 'application/json' },
      })

      return appearanceDeserializer(data)
    } catch (error) {
      console.log('error while creating getting appearance :(')

      return Promise.reject(error)
    }
  }

  static updateOwnAppearance = async (appearance: Appearance) => {
    try {
      const { data } = await axios.put('/appearance', appearanceSerializer(appearance), {
        headers: { Accept: 'application/json' },
      })

      return appearanceDeserializer(data)
    } catch (error) {
      console.log('error while creating setting appearance :(')

      return Promise.reject(error)
    }
  }

  static getOwnLogo = async (): Promise<LogoInfo> => {
    try {
      const { data } = await axios.get('/logo', {
        headers: { Accept: 'application/json' },
      })

      return data
    } catch (error) {
      console.log('error while creating getting logo :(')

      return Promise.reject(error)
    }
  }

  static updateOwnLogo = async (file: File): Promise<LogoInfo> => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      const { data } = await axios.post('/logo', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      return data
    } catch (error) {
      console.log('error while creating setting appearance :(')

      return Promise.reject(error)
    }
  }
}
