import { useEffect } from 'react'

const useGoogleMaps = () => {
  useEffect(() => {
    const googleMaps = document.createElement('script')
    googleMaps.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places,geometry`
    googleMaps.async = true
    document.body.prepend(googleMaps)

    const script = document.createElement('script')
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_TRACKING_ID}`
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
      document.body.removeChild(googleMaps)
    }
  }, [])
}

export default useGoogleMaps
