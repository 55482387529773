import Axios from 'core/axios'
import type { ClientI, UploadedDocument } from 'core/store/reducers/clientReducer'

import type { LDApiReturnValue, RequestParamsI } from '../users/types'
import { formRequestUrl, serializeRequestFilters } from '../utils/requestsUtils'

const clientSerializer = (client: Partial<ClientI>) => {
  const {
    name,
    email,
    countryCode,
    city,
    zip,
    address,
    dunsNumber,
    vatNumber,
    tradeLicenceNumber,
    registrationNumber,
    phone,
    tenant,
    externalId,
  } = client

  return {
    name,
    email,
    countryCode,
    city,
    zip,
    address,
    dunsNumber,
    vatNumber,
    tradeLicenceNumber,
    registrationNumber,
    phone,
    externalId,
    ...(tenant && tenant.id ? { tenant: tenant.id } : {}),
  }
}

export class ClientsApi {
  static async getByTenantId(tenantId: number | string): Promise<LDApiReturnValue<ClientI>> {
    const { data } = await Axios.get(`clients?pagination=false&tenant=${tenantId}`)
    const { 'hydra:member': clients, 'hydra:totalItems': totalItems } = data

    return {
      data: clients,
      meta: {
        totalItems,
      },
    }
  }

  static async getAllClients(
    params: Record<string, { values: Array<string>; formRequestParamAsArray: boolean }> = {},
    appendParams?: string
  ): Promise<LDApiReturnValue<ClientI>> {
    const { data } = await Axios.get(
      `clients?pagination=false&${serializeRequestFilters(params)}${appendParams ? appendParams : ''}`
    )

    const { 'hydra:member': clients, 'hydra:totalItems': totalItems } = data

    return {
      data: clients,
      meta: {
        totalItems,
      },
    }
  }

  static async getOne(clientId: string): Promise<ClientI> {
    const { data } = await Axios.get(`clients/${clientId}?groups[]=tenant&groups[]=files`, {
      headers: { Accept: 'application/ld+json' },
    })

    return data as ClientI
  }

  static async update(client: ClientI) {
    const { data } = await Axios.put(`clients/${client.id}`, clientSerializer(client), {
      headers: { Accept: 'application/ld+json' },
    })

    return data
  }

  static async create(params: Partial<ClientI>) {
    const { data } = await Axios.post('clients', clientSerializer(params), {
      headers: { Accept: 'application/ld+json' },
    })

    return data
  }

  static async getMany(params: RequestParamsI): Promise<LDApiReturnValue<ClientI>> {
    const { data } = await Axios.get(formRequestUrl('clients', params), {
      headers: { Accept: 'application/ld+json' },
    })

    return {
      data: data['hydra:member'],
      meta: {
        totalItems: data['hydra:totalItems'],
      },
    }
  }

  static async deleteDocument(fileID: string | number) {
    return Axios.delete(`client-files/${fileID}`)
  }

  static async createDocument(clientId: string | number, document: UploadedDocument) {
    const formData = new FormData()
    formData.append('file', document.file)
    formData.append('type', document.type)
    formData.append('client', clientId.toString())

    return Axios.post('client-files', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }

  static async getClientCampaigns(clientId: string | number, appendParams?: string) {
    const { data } = await Axios.get(`clients/${clientId}/campaigns${appendParams ? appendParams : ''}`, {
      headers: { Accept: 'application/ld+json' },
    })

    return {
      data: data['hydra:member'],
      meta: {
        totalItems: data['hydra:totalItems'],
      },
    }
  }
}
