import axios from 'core/axios'
import type { Ad } from 'core/type'

import type { AddedImage, IImage } from '../../common/components/Images/types'
import { BinaryLoader } from '../utils/BinaryLoader'

class ImagesService {
  list = async (id: number): Promise<Array<IImage>> => {
    try {
      const { data } = await axios.get(`images?ad=${id}`, {
        headers: { Accept: 'application/json' },
      })

      return data
    } catch (error) {
      console.log('error while getting images')

      return Promise.reject(error)
    }
  }

  create = async (imageFile: FormData): Promise<IImage> => {
    try {
      const { data } = await axios.post('images', imageFile, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      return data
    } catch (error) {
      console.log('error while updating images')

      return Promise.reject(error)
    }
  }

  update = async (id: string, images: Ad) => {
    try {
      await axios.put(`images/${id}`, { ...images })
    } catch (error) {
      console.log('error while updating images')

      return Promise.reject(error)
    }
  }

  delete = async (id: number) => {
    try {
      await axios.delete(`images/${id}`, {
        headers: { Accept: 'application/json' },
      })
    } catch (error) {
      console.log('error while deleting images')
      // @ts-ignore

      return Promise.reject(error.response.data.detail)
    }
  }

  copyList = async (images: Array<IImage | AddedImage>): Promise<Array<AddedImage>> => {
    try {
      const newPromiseArr: Promise<AddedImage>[] = images.map(async (image) => {
        if ('file' in image) {
          return Promise.resolve(image)
        } else {
          const file = await BinaryLoader.byUrl(image.url)

          return {
            id: null,
            url: image.url,
            file: file,
            keyStub: Date.now(),
            group: image.group,
            type: image.type,
          }
        }
      })

      return Promise.all(newPromiseArr)
    } catch (error) {
      console.log('error while getting images')

      return Promise.reject(error)
    }
  }
}

export const ImageAPI = new ImagesService()
