import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ImageCategoryViewer } from 'common/components/Images/ImageCategoryViewer'
import type { EnhancedCampaign } from 'core/type'
import { AdAttachmentType, TCampaignType } from 'core/type'
import translate from 'i18next'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { IMAGE_GROUP, IMAGE_RESTRICTIONS, IMAGE_TYPE } from '../../../../../common/components/Images/types'
import { ReadonlyField } from '../../../../../common/components/Typography/ReadonlyField'
import { DynamicStep } from '../../../common/Components/FormGenerator/FormBuilder'
import { getFileNameFromURL } from '../../../common/utils/AMPHTMLToImage'
import type { CampaignFailDetails } from '../../common/CampaignFailDetails'
import { AdServerErrorMessage } from '../common/AdServerErrorMessage'

const createStyles = makeStyles((theme) => ({
  root: {},
  disabled: {
    '& .Mui-disabled': {
      color: 'rgb(0, 0, 0)',
    },
  },
  link: {
    position: 'relative',
    zIndex: 1,
    color: theme.palette.primary.main,
    maxWidth: 400,
    fontSize: 14,
  },
}))

interface Props {
  campaign: EnhancedCampaign
  errorDetails: CampaignFailDetails
}

export const CampaignManagementAds: React.FC<Props> = ({ campaign, errorDetails }) => {
  const { t } = useTranslation()
  const styles = createStyles()

  const { images, adAttachmentType, files, type: adType } = campaign
  const isFileError = useMemo(() => errorDetails.fieldNames?.includes('files'), [errorDetails])
  const isUrlError = useMemo(() => errorDetails.fieldNames?.includes('url'), [errorDetails])

  return (
    <Box>
      {adType === TCampaignType.native && (
        <>
          <Typography variant='h6'>{t('campaigns.adSettingsDescription')}</Typography>

          <Grid container item justifyContent={'flex-start'} xs={12} component='form'>
            <Grid container spacing={6} justifyContent='flex-start'>
              <Grid item xs={10}>
                <Box mt={6}>
                  <Box mb={3}>
                    <Typography variant='subtitle1' component='div'>
                      {t('campaigns.adText')}
                    </Typography>
                  </Box>
                  <Grid container item justifyContent={'flex-start'} direction={'column'} spacing={6} xs={12}>
                    {
                      DynamicStep({
                        label: '',
                        dependencies: [],
                        fieldsOrder: ['url', 'headline', 'description', 'businessName'],
                        fieldsAdditionalProps: {
                          longHeader: { minRows: 4, multiline: true },
                          description: { minRows: 4, multiline: true },
                          url: {
                            error: isUrlError,
                            errorMessage: errorDetails.failureMessage,
                            href: true,
                            label: 'linkedUrl',
                            trim: 'true',
                            placeholder: 'https://example.com',
                          },
                        },
                        additionalValidation: {},
                        schemaRequestUrl: 'schemas/Ad/native?type=input&operationType=item',
                        additionalQueryParams: {},
                        initialValues: campaign.ad as EnhancedCampaign['ad'],
                        fieldsToOmit: ['type', 'subtype', 'campaign', 'name'],
                        translationsKey: 'campaigns.fields',
                        renderFunction: (props, translationsKey) => (formik) => {
                          return props.map((prop, index) => {
                            return (
                              <Grid item key={index}>
                                {prop.error && (
                                  <Box mb={4}>
                                    <AdServerErrorMessage
                                      FAQHref={3}
                                      message={
                                        // @ts-ignore
                                        translate.t(`campaigns.adServerErrorDescription.${prop.errorMessage!}`)
                                      }
                                    />
                                  </Box>
                                )}
                                <ReadonlyField
                                  name={'test-name'}
                                  error={!!prop.error}
                                  label={translate.t(`${translationsKey}.${(prop.label || prop.name!) as string}`)}
                                  body={formik.values[prop.name as string]}
                                  // @ts-ignore
                                  href={prop.href}
                                />
                              </Grid>
                            )
                          })
                        },
                        onSubmit: () => async () => true,
                      }).Content
                    }
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <ImageCategoryViewer
            title={`1:1 ${t('campaigns.marketingImages')}`}
            images={images}
            group={IMAGE_GROUP.IMAGE}
            allowedResolutions={[IMAGE_RESTRICTIONS[IMAGE_TYPE.SQUARE]]}
            types={[IMAGE_TYPE.SQUARE]}
            campaignFailDetails={errorDetails}
          />
          <ImageCategoryViewer
            title={`1.91:1 ${t('campaigns.marketingImages')}`}
            images={images}
            group={IMAGE_GROUP.IMAGE}
            allowedResolutions={[IMAGE_RESTRICTIONS[IMAGE_TYPE.HORIZONTAL]]}
            types={[IMAGE_TYPE.HORIZONTAL]}
            campaignFailDetails={errorDetails}
          />
        </>
      )}
      {adType === TCampaignType.banner && (
        <>
          <Typography variant='h6'>{t('campaigns.adSettingsDescription')}</Typography>
          <Box mt={2} mb={7}>
            <>
              {
                DynamicStep({
                  label: '',
                  dependencies: [],
                  fieldsOrder: [],
                  fieldsAdditionalProps: {
                    url: {
                      error: isUrlError,
                      errorMessage: errorDetails.failureMessage,
                      href: true,
                      trim: 'true',
                      label: t('campaigns.linkedUrl'),
                    },
                  },
                  additionalValidation: {},
                  schemaRequestUrl: 'schemas/Ad/banner?type=output&operationType=item',
                  additionalQueryParams: {},
                  initialValues: {
                    url: campaign.ad.url,
                  } as any as EnhancedCampaign,
                  fieldsToOmit: ['type', 'subtype', 'campaign', 'name'],
                  translationsKey: 'campaigns',
                  renderFunction: (props, translationsKey) => (formik) => {
                    return (
                      <>
                        {props.map((prop, key) => {
                          return (
                            <Grid item key={key} xs={6}>
                              {prop.error && (
                                <Box mb={4}>
                                  <AdServerErrorMessage
                                    FAQHref={3}
                                    message={
                                      // @ts-ignore
                                      translate.t(`campaigns.adServerErrorDescription.${prop.errorMessage}`)
                                    }
                                  />
                                </Box>
                              )}
                              <ReadonlyField
                                name={'test-name'}
                                error={!!prop.error}
                                // @ts-ignore
                                href={prop.href}
                                label={prop.label || translate.t(`${translationsKey}.fields.${prop.name}`)}
                                body={formik.values[prop.name as string]}
                              />
                            </Grid>
                          )
                        })}
                      </>
                    )
                  },
                  onSubmit: () => async () => Promise.resolve(true),
                }).Content
              }
            </>
          </Box>
          <Typography variant='h6' gutterBottom>
            {t('campaigns.bannerAdMedia')}
          </Typography>

          <Grid container>
            {adAttachmentType === AdAttachmentType.IMAGE && (
              <ImageCategoryViewer
                title={t('campaigns.imageSize')}
                images={images}
                allowedResolutions={[IMAGE_RESTRICTIONS[IMAGE_TYPE.RECTANGLE]]}
                group={IMAGE_GROUP.IMAGE}
                types={[IMAGE_TYPE.RECTANGLE]}
                campaignFailDetails={errorDetails}
              />
            )}
            {adAttachmentType === AdAttachmentType.AMPHTML && (
              <Box mt={8}>
                <Typography variant='subtitle1' gutterBottom>
                  AMPHTML
                </Typography>

                <Box mt={1} component={'div'}>
                  {isFileError && (
                    <AdServerErrorMessage
                      FAQHref={3}
                      message={translate.t(`campaigns.adServerErrorDescription.${errorDetails?.failureMessage}`)}
                    />
                  )}
                  {files.map((file, index) => {
                    return (
                      <Box key={index} marginTop={4}>
                        <a
                          style={file.url === errorDetails.failedResource ? { color: '#FA0808' } : {}}
                          className={styles.link}
                          href={file.url || 'Not downloaded yet'}
                          onClick={(e) => {
                            if (!file.url) {
                              e.preventDefault()
                              alert('AMPHTML file have not been uploaded yet')
                            }
                          }}
                          rel='noreferrer'
                          target='_blank'
                        >
                          {file.url ? getFileNameFromURL(file.url) : 'Not added yet'}
                        </a>
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            )}
          </Grid>
        </>
      )}
    </Box>
  )
}
