import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from 'core/store/store'

import type { TCampaignType } from '../../type'

interface AdServers {
  isLoading: boolean
  adServers: Array<TCampaignType>
}

const initialState: AdServers = {
  isLoading: false,
  adServers: [],
}

const adServers = createSlice({
  name: 'adServers',
  initialState,
  reducers: {
    adServersSuccess: (state, { payload }) => {
      state.isLoading = false
      state.adServers = payload
    },
    adServersLoading: (state) => {
      state.isLoading = true
    },
    adServersError: (state) => {
      state.isLoading = false
    },
  },
})

export const { adServersSuccess } = adServers.actions

export const adServersSelector = (state: RootState) => state.adServers.adServers

export default adServers.reducer
