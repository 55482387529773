import type { AlertColor } from '@mui/material/Alert/Alert'

export const SET_SNACKBAR = 'SET_SNACKBAR'

export interface ISnackbars {
  snackbarOpen: boolean
  snackbarType: string
  snackbarMessage: string
}

const initialState: ISnackbars = {
  snackbarOpen: false,
  snackbarType: 'success',
  snackbarMessage: '',
}

const BuildSnackbar = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SNACKBAR:
      // eslint-disable-next-line no-case-declarations
      const { snackbarOpen, snackbarMessage, snackbarType } = action

      return {
        ...state,
        snackbarOpen,
        snackbarType,
        snackbarMessage,
      }
    default:
      return state
  }
}

export const setSnackbar = (snackbarOpen: boolean, snackbarType: AlertColor, snackbarMessage = '') => ({
  type: SET_SNACKBAR,
  snackbarOpen,
  snackbarType,
  snackbarMessage,
})

export default BuildSnackbar
