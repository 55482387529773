import { Button } from '@material-ui/core'
import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded'
import React from 'react'

import { LinkUnstyled } from '../Links/LinkUnstyled'

export const ReturnButton = ({
  link,
  text,
  redirect = false,
  disabled = false,
}: {
  text: string
  link: string
  redirect?: boolean
  disabled?: boolean
}) => {
  const handleRedirect = (e: React.MouseEvent) => {
    if (redirect) {
      e.preventDefault()
      window.location.href = link
    }
  }

  const Content = (
    <Button
      style={{ paddingLeft: 0, maxHeight: 32, paddingTop: 0 }}
      disabled={disabled}
      startIcon={<ArrowBackRounded fontSize='small' />}
      color='primary'
      variant='text'
      onClick={handleRedirect}
    >
      {text}
    </Button>
  )

  return <LinkUnstyled to={link}>{Content}</LinkUnstyled>
}
